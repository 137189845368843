import axios from "axios";
import constants from "../constants";
import {getItem, KEY} from "../database";

const instance = axios.create({
	baseURL: constants.BASE_API,
	timeout: constants.SERVER_TIMEOUT,
});

instance.interceptors.request.use(config => {
	let userInfo = getItem(KEY.USER_INFORMATION);
	config.headers = {
		...config.headers,
		Authorization: userInfo ? userInfo['login_token'] : null,
		'Access-Control-Allow-Origin': '*',
		'Content-type': 'application/json',
	};
	return config;
});

instance.interceptors.response.use(response => {
	if (response.status !== 200) {
		return { message: 'Error ... ' + response.statusText, success: false };
	}
	return response.data;
}, error => {
	return { message: 'Error ...' + error.message, success: false };
});

export default instance;
