import { combineReducers } from 'redux';
import chartReducer from './chartReducer';
import memberReducer from './memberReducer';
import appReducer from './appReducer';
import transactionReducer from './transactionReducer';

const rootReducer = combineReducers({
	chartReducer,
	memberReducer,
	appReducer,
	transactionReducer
});

export default rootReducer;
