import _ax from './_ax';

export function getChallenge(status = 'live', options = {}){
	return _ax.get(`challenge/${status}`);
}

export function getChallengeBoard(params){
	return _ax.get('challenge/board', { params });
}

export function getChallengeInfo(params){
	return _ax.get('challenge/info', { params });
}
