import { createReducer } from 'redux-act';
import { updateTransactionAction } from "../actions";
import constants from "../../lib/constants";

export default createReducer({
	[updateTransactionAction]: (state, payload) => {
		return {...state, ...payload};
	},
}, {
	selected_tab: constants.TRANSACTION_STATUS.PENDING,
	transactions: [],
	total_bet_open: 0,
	limit: 10,
	page: 1
});
