import {Component} from "react";
import {connect} from "react-redux";

class Expert extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { experts, onBack, onSelectExpert } = this.props;
        return <div className='container'>
            <div className='expert-group'>
                <div style={{ marginBottom: '22px' }}>
                    <button onClick={onBack} className='btn btn-go-back'><i className='fas fa-arrow-left' /> Trở lại</button>
                </div>
                <div className='text-center'>
                    <h3>Danh sách chuyên gia</h3>
                </div>
                <div className='expert-list'>
                    {(experts || []).map(item => {
                        return <div key={item.username} onClick={() => onSelectExpert(item)} className='expert-item'>
                            <div><span>Mentor:</span><span>{item.username}</span></div>
                            <div><span>Copy:</span><span>{item.follower_count}</span></div>
                            <div><span>Win:</span><span>{item.percent_win}%</span></div>
                            <div><span>Chiết khấu:</span><span>{item.receive_commission_percent}%</span></div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {

    };
}

export default connect(mapStateToProps, null)(Expert);
