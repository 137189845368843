import { takeLatest, put, call} from 'redux-saga/effects';
import store from '../store';
import constants from "../../lib/constants";

import {
	updateTransactionAction,
	fetchingTransactionAction
} from '../actions';
import api from "../../lib/api";

export function* watchFetchingTransaction() {
    yield takeLatest(fetchingTransactionAction, fetchingTransactionSaga)
}

function* fetchingTransactionSaga() {
    try {
		let { transactions, limit, page, selected_tab } = store.getState().transactionReducer || {};
        let { data, success } = yield call(api.getTransaction, { page, limit, transaction_type: constants.TRANSACTION_TYPE.BET, transaction_status: selected_tab  });
        if (success) {
           yield put(updateTransactionAction({ total_bet_open: data.total_bet_open, transactions: data.data }))
        }
    } catch (error) {}
}
