import { createAction } from 'redux-act';

export const updateChartAction = createAction('update chart');

export const updateMemberAction = createAction('update member');
export const clearMemberAction = createAction('clear member');

export const updateAppConfigAction = createAction('update app config');
export const fetchingConfig = createAction('fetching config');
export const fetchingConfigSuccess = createAction('fetching config success');
export const updateAppWindowWidthAction = createAction('update window width');

export const fetchingUser = createAction('fetching user');
export const fetchingUserFail = createAction('fetching user fail');
export const fetchingUserSuccess = createAction('fetching user success');

export const logoutUser = createAction('logout user');

export const fetchingTransactionAction = createAction('fetching transaction action');
export const updateTransactionAction = createAction('update transaction action');
