import '../../assets/css/copy.css';

import { Component } from 'react';
import DataTable  from 'react-data-table-component';

import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../redux/actions";
import {withRouter} from "react-router-dom";
import {formatNumber, getUrl, setClassNameCondition, showNotification, isDesktop} from "../../lib/helper";
import api from "../../lib/api";
import moment from "moment";
import DateRangePicker from "../../lib/custom/DateRangePicker";
import constants from "../../lib/constants";
import Expert from "./expert";

class Copy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.memberReducer,
			selected_tab: 'setup',
			expert_warning_message: '',
			expert_warning_success: false,
			expert_info: null,

			page: 1,
			per_page: 10,
			transaction_list: [],
			transaction_total: 0,
			sort_selector: 'created_at',
			sort_direction: 'desc',
			filter_from: moment().subtract(1, 'months'),
			filter_to: moment(),

			expert_list: null
		};
		this.columns = [
			{
				name: 'Mã giao dịch',
				selector: 'code',
				sortable: false,
			},
			{
				name: 'Thời gian',
				selector: 'created_at',
				sortable: true,
				format: (row, index) => (moment(row.created_at).format('HH:mm DD/MM')),
			},
			{
				name: 'Chuyên gia',
				selector: 'copy_bet_username',
				sortable: true,
				allowOverflow: true,
				format: (row, index) => (row.copy_bet_username),
			},
			{
				name: 'Đã chọn',
				selector: 'bet_condition',
				sortable: true,
				format: (row) => (<span className={'bet_condition '+row.bet_condition}>{row.bet_condition === 'up' ? 'Tăng' : 'Giảm'}</span>)
			},
			{
				name: 'Trạng thái',
				selector: 'transaction_status_label',
				data: 'transaction_status',
				sortable: true,
				allowOverflow: true,
				format: (row, index) => (<span className={'transaction_status ' + row.transaction_status}>{row.transaction_status_label}</span>),
			},
			{
				name: 'Giá đặt ($)',
				selector: 'bet_value',
				sortable: true,
				right: true,
				format: (row, index) => (<span>{formatNumber(row.bet_value)}</span>),
			},
			{
				name: 'Lợi nhuận ($)',
				selector: 'value',
				sortable: true,
				right: true,
				format: (row, index) => (<span style={{ color: row.value < 0 ? 'red' : 'green' }}>{formatNumber(row.value)}</span>),
			},
		];
	}

	onSelectTab = (tab) => (e) => {
		e && e.preventDefault();
		this.setState({ selected_tab: tab }, () => {
			if(tab === 'history'){
				this.loadTransaction();
			}
		});
	}

	onChangeInput = (type, is_number = true) => (e) => {
		let value = e.target.value.toString();
		if(is_number){
			value = parseFloat(value.replace(/,/g,''));
		}
		let {copy_trade_setting} = this.state;
		this.setState({ copy_trade_setting: { ...copy_trade_setting, [type]: value } }, () => {
			if(type === 'expert_username'){
				this.loadExpertInfo();
			}
		});
	}

	loadExpertInfo = () => {
		clearTimeout(this.timeoutLoadExpertInfo);
		this.timeoutLoadExpertInfo = setTimeout(() => {
			let {copy_trade_setting: { expert_username }} = this.state;
			api.getExpertInfo({ expert_username }).then(({ success, message, data }) => {
				this.setState({ expert_warning_message: message, expert_info: data, expert_warning_success: success })
			});
		}, 400);
	}

	loadTransaction = () => {
		let { page, per_page, sort_selector, sort_direction, filter_from, filter_to } = this.state;
		api.getTransaction({ limit: per_page, page, transaction_type: constants.TRANSACTION_TYPE.BET, sort_selector, sort_direction,
			filter_from: filter_from.format('YYYY-MM-DD'),
			filter_to: filter_to.format('YYYY-MM-DD'),
			is_copy_trade: true
		}).then(({ data, success }) => {
			if(success){
				this.setState({ transaction_list: data.data, transaction_total: data.total });
			}
		})
	}


	componentDidMount() {
		this.loadDashboard();
		this.loadExpertInfo();
	}

	loadDashboard = () => {
		api.getDashboard().then(({ data, success }) => {
			if(success){
				let {total_win, total_all, follower_count} = data || {};
				this.setState({ total_all, total_win, follower_count });
			}
		});
	}

	onChangeStatus = () => {
		this.setState({ copy_trade_setting: {...this.state.copy_trade_setting, is_active: !this.state.copy_trade_setting?.is_active } });
	}

	onClickBecomeExpert = () => {
		api.postBecomeExpert().then(({success, message}) => {
			message && showNotification({ message, type: success ? 'success' : 'danger' });
			success && this.props.fetchingUser();
		});
	}

	onSaveSetting = () => {
		let { expert_info, copy_trade_setting } = this.state;
		let { recommend_default_trade, receive_commission_percent, expert_username, point_type, copy_method, copy_value, stop_win, stop_lose, is_active } = copy_trade_setting || {};
		let { minimum_bet } = this.props.appReducer;
		if(copy_method === 'permanent'){
			copy_value = copy_value < minimum_bet ? minimum_bet : copy_value;
		} else if(copy_method === 'percent_on_expert' && expert_info){
			copy_value = expert_info?.recommend_default_trade;
		}

		api.postSaveCopyTradeSetting({ recommend_default_trade, receive_commission_percent, expert_username, point_type, copy_method, copy_value, stop_win, stop_lose, is_active })
			.then(({success, message})=>{
				message && showNotification({ message, type: success ? 'success' : 'danger' });
				success && this.props.fetchingUser();
			});
	}

	handlePageChange = (page) => {
		this.setState({ page }, this.loadTransaction)
	}

	handlePerRowsChange = (per_page) => {
		this.setState({ per_page }, this.loadTransaction)
	}

	onSort = (column, sortDirection) => {
		this.setState({ sort_selector: column.selector, sort_direction: sortDirection }, this.loadTransaction)
	}

	handleChangeDateRange = (start, end) => {
		this.setState({ filter_from: start, filter_to: end }, this.loadTransaction)
	}

	onShowExpertList = () => {
		api.getExpertList().then(({ success, data }) => {
			success && this.setState({ expert_list: data })
		});
	}

	onSelectExpert = expert => {
		let { copy_trade_setting } = this.state;
		this.setState({ copy_trade_setting: { ...copy_trade_setting, expert_username: expert.username }, expert_list: null }, this.loadExpertInfo);
	}

	render() {
		let { expert_list, selected_tab, total_win, total_all, expert_status, copy_trade_setting, expert_warning_message, expert_info, expert_warning_success, username, transaction_list, transaction_total, filter_from, filter_to, follower_count } = this.state;
		let { minimum_bet } = this.props.appReducer;
		let { is_active, stop_lose, stop_win, copy_value, expert_username, point_type, copy_method, receive_commission_percent, } = copy_trade_setting || {};
		if(!copy_method){
			copy_method = 'permanent';
		}
		let percent_win = total_all > 0 ? Math.ceil(total_win*100/total_all) : 0;

		if(copy_value !== '' && copy_value !== undefined && copy_value !== null){
			if(copy_method === 'permanent'){

			} else if(copy_method === 'percent_on_expert' && expert_info){
				copy_value = expert_info?.recommend_default_trade;
			}
		}

		let copy_value_symbol = (copy_method === 'permanent') ? '$' : '%'

		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent copy_trade">
					<div className='community'>
						<div data-v-46bc7c21="" data-v-b0e45e8c="" className="page">
							<div data-v-5884b68b="" data-v-46bc7c21="" className="backgroundDark">
								<div data-v-5884b68b="" className="container">
									<ul data-v-5884b68b="" id="pills-tab" role="tablist" className="nav nav-pills menuHeaderLink">
										<li data-v-5884b68b="" className="nav-item">
											<a data-v-5884b68b="" href="#" onClick={this.onSelectTab('setup')} className={setClassNameCondition(selected_tab === 'setup', 'router-link-exact-active router-link-active', '', 'nav-link')}>Cài Đặt</a>
										</li>
										<li data-v-5884b68b="" className="nav-item">
											<a data-v-5884b68b="" href="#" onClick={this.onSelectTab('history')} className={setClassNameCondition(selected_tab === 'history', 'router-link-exact-active router-link-active', '', 'nav-link')}>Lịch Sử</a>
										</li>
										<li data-v-5884b68b="" className="nav-item">
											<a data-v-5884b68b="" href="#" onClick={this.onSelectTab('expert')} className={setClassNameCondition(selected_tab === 'expert', 'router-link-exact-active router-link-active', '', 'nav-link')}>Khu Vực Chuyên Gia</a>
										</li>
									</ul>
								</div>
							</div>
							{selected_tab ==='setup' && <div data-v-22dfce66="" data-v-b0e45e8c="" className="bodyContent affiliatePage">
								<div data-v-22dfce66="">
									<div data-v-22dfce66="">
										<div data-v-22dfce66="" className="banner">
											<div data-v-22dfce66="" className="content">
												{expert_list
													? <Expert onSelectExpert={this.onSelectExpert} onBack={() => this.setState({ expert_list: null })} experts={expert_list} />
													: <div data-v-22dfce66="" className="container h-100">
														<div className='row'>
															<div className='col-md-6'>
																<div className='mb-5'>
																	<div className='form-group'>
																		<span>Tên chuyên gia</span>
																		<div className='input-group-append'>
																			<input type="text" value={expert_username} onChange={this.onChangeInput('expert_username', false)} className='form-control'/>
																			<span className='input-append' onClick={this.onShowExpertList} style={{ textDecoration: 'underline', cursor: 'pointer' }}><i className='fas fa-user-md' style={{ color: 'lime' }} /></span>
																		</div>
																		{expert_warning_message && <label style={{ color: expert_warning_success ? 'yellow' : '#ff4c4c', marginTop: '2px' }}><i className='fas fa-exclamation-triangle' /> {expert_warning_message}</label>}
																	</div>
																	<div className='form-group'>
																		<span>Tài khoản giao dịch</span>
																		<select value={point_type} onChange={this.onChangeInput('point_type', false)} className='form-control'>
																			<option value="demo">Tài khoản Demo</option>
																			<option value="real">Tài khoản thực</option>
																		</select>
																	</div>
																	<div className='form-group'>
																		<span>Phương pháp copy</span>
																		<select value={copy_method} onChange={this.onChangeInput('copy_method', false)} className='form-control'>
																			<option value="permanent">Cố định số tiền giao dịch</option>
																			<option value="percent_per_balance">Phần trăm so với vốn hiện có</option>
																			<option value="percent_on_expert">Phần trăm vốn mặc định mà chuyên gia khuyến khích</option>
																		</select>
																	</div>
																	{copy_method !== 'percent_on_expert' && <div className='form-group'>
																		<span>Giá trị 1 lệnh (tối thiểu ${formatNumber(minimum_bet)})</span>
																		<div className='input-group-prepend'>
																			<span className='input-prepend'>{copy_value_symbol}</span>
																			<input type="text" disabled={copy_method === 'percent_on_expert'} value={formatNumber(copy_value, true)} onChange={this.onChangeInput('copy_value')} className='form-control'/>
																		</div>
																	</div>}
																	<div className='form-group'>
																		<span>Mục tiêu chốt lãi</span>
																		<div className='input-group-prepend'>
																			<span className='input-prepend'>$</span>
																			<input type="text" value={formatNumber(stop_win, true)} onChange={this.onChangeInput('stop_win')} className='form-control'/>
																		</div>
																	</div>
																	<div className='form-group'>
																		<span>Mục tiêu cắt lỗ</span>
																		<div className='input-group-prepend'>
																			<span className='input-prepend'>-$</span>
																			<input type="text" value={formatNumber(stop_lose, true)} onChange={this.onChangeInput('stop_lose')} className='form-control'/>
																		</div>
																	</div>
																	<div className='form-group'>
																		<label data-v-425bef87="" data-v-9792f17c="" className="toogle mb-0" onClick={this.onChangeStatus}>
																			<div data-v-425bef87="" className={setClassNameCondition(is_active, "on", 'off', 'switch-core')}>
																				<span data-v-425bef87="" className={setClassNameCondition(is_active, 'left', 'right', 'switch-label')}>{is_active ? 'On' : 'Off'}</span>
																				<div data-v-425bef87="" className={setClassNameCondition(is_active, 'right', 'left', 'switch-button')} />
																			</div>
																		</label>
																		<b style={{ marginLeft: '8px', color: 'white' }}>Trạng thái</b>
																		<label className={setClassNameCondition(is_active, 'badge-success', 'badge-danger', 'ml-3 badge')}>{is_active ? "Đang bật" : 'Đang tắt'}</label>
																	</div>
																	<div className='form-group mt-5'>
																		<button className='button wbtn btn-radius btn-large btn-border color-white btn-secondary w-100' onClick={this.onSaveSetting}>Lưu Cài Đặt</button>
																	</div>
																</div>
															</div>
															<div className='col-md-6'>
																<div>
																	<div className="alert alert-success" role="alert">
																		<i className='fas fa-hand-point-right' /> Click vào biểu tượng chuyên gia <i className='fas fa-user-md' /> để chọn chuyên gia phù hợp
																	</div>
																	<div className="alert alert-warning" role="alert">
																		<i className='fas fa-hand-point-right' /> Chọn loại tài khoản muốn giao dịch theo Chuyên gia tại mục <code>Tài khoản giao dịch</code>
																	</div>
																	<div className="alert alert-primary" role="alert">
																		<i className='fas fa-hand-point-right' /> Khi đạt mục tiêu <code>chốt lãi</code> hoặc <code>cắt lỗ</code>. Hệ thống sẽ ngưng giao dịch đến hết ngày đó
																	</div>
																	<div className="alert alert-info" role="alert">
																		<i className='fas fa-hand-point-right' /> Nếu cài <code>chốt lãi , cắt lỗ bằng 0</code> hoặc <code>trạng thái tắt</code> hoặc <code>Tên chuyên gia không đúng</code> thì tài khoản của bạn sẽ không giao dịch.
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>}
							{selected_tab === 'history' && <div data-v-10df954e="" style={{ paddingBottom: '40px' }} data-v-46bc7c21="" className="portfolioStats">
								<div data-v-22dfce66="" className="banner">
									<div data-v-22dfce66="" className="content">
										<div data-v-22dfce66="" className="container-fluid h-100">
											<div data-v-7a0c51c4=""
												 className="headerContent d-flex flex-lg-row flex-column justify-content-between align-items-center mb-3">
												<h1 data-v-7a0c51c4="" className="font-30 color-white font-weight-700 text-capitalize">Lịch sử Giao dịch Copy Trade</h1>
												<div data-v-7a0c51c4="" className="d-lg-flex align-items-end">
													<DateRangePicker handleChangeDateRange={this.handleChangeDateRange} initStart={filter_from} initEnd={filter_to} />
												</div>
											</div>
											<div data-v-7a0c51c4="" className="table-responsive"  style={{ borderRadius: '3px' }}>
												<DataTable
													columns={this.columns}
													data={transaction_list}
													pagination
													paginationServer
													paginationTotalRows={transaction_total}
													onChangeRowsPerPage={this.handlePerRowsChange}
													onChangePage={this.handlePageChange}
													sortServer
													onSort={this.onSort}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>}
							{selected_tab === 'expert' && <div data-v-10df954e="" style={{ paddingTop: '10px' }} data-v-46bc7c21="" className="portfolioStats">
								<div data-v-22dfce66="" className="banner">
									<div data-v-22dfce66="" className="content">
										<div data-v-22dfce66="" className="container h-100">
											<div className='row'>
												<div data-v-417299ea="" className="col-md-12 mb-2">
													{expert_status === 'approved' ? <div>
														<div className="alert alert-success" role="alert">
															<i className='fas fa-user-md' /> Bạn đang là chuyên gia, Tên chuyên gia của bạn là: <b>{username}</b>
														</div>
														<div className="alert alert-info" role="alert">
															<i className='fas fa-trophy' /> Tỉ lệ thắng hiện tại của bạn là: <b>{percent_win || 0}%</b>
														</div>
														<div className="alert alert-warning" role="alert">
															<i className='fas fa-copy' /> Số người đang copy trade của bạn là: <b>{formatNumber(follower_count, true) || 0}</b>
														</div>
														<div className='mb-5 mt-4'>
															{/*<div className='form-group'>*/}
															{/*	<span>Phần trăm vốn mặc định khuyến khích với mỗi giao dịch của nhà đầu tư</span>*/}
															{/*	<div className='input-group-prepend'>*/}
															{/*		<span className='input-prepend'>%</span>*/}
															{/*		<input type="number" max={100} min={0} value={recommend_default_trade} onChange={this.onChangeInput('recommend_default_trade')} className='form-control'/>*/}
															{/*	</div>*/}
															{/*</div>*/}
															<div className='form-group'>
																<span>Hoa hồng muốn nhận được trên số tiền lãi mang lại cho nhà đầu tư (phần trăm chiết khấu)</span>
																<div className='input-group-prepend'>
																	<span className='input-prepend'>%</span>
																	<input type="number" max={100} min={0} value={receive_commission_percent} onChange={this.onChangeInput('receive_commission_percent')} className='form-control'/>
																</div>
															</div>
															<div className='form-group mt-5'>
																<button onClick={this.onSaveSetting} className='button wbtn btn-radius btn-large btn-border color-white btn-secondary w-100'>Lưu Cài Đặt</button>
															</div>
														</div>
													</div> : <>
														<h3 data-v-417299ea="" className="font-18 color-white-50 mb-3">Bạn cần có tỉ lệ thắng từ <b>50%</b> trở lên để có thể đăng ký làm chuyên gia</h3>
														<h4 data-v-417299ea="" className="font-18 color-white-50 mb-3">Tỉ lệ thắng hiện tại của bạn: <b>{percent_win}%</b></h4>
														{percent_win >= 50 && expert_status === 'none' && <div>
															<button data-v-22dfce66=""
																	type="button" onClick={this.onClickBecomeExpert}
																	className="btn button wbtn btn-green btn-large"><i className='fas fa-user-md mr-2' /> Đăng Ký Làm Chuyên Gia</button>
														</div>}
														{expert_status === 'wait_confirm' && <div className="alert alert-warning" role="alert">
															<i className='fas fa-exclamation-triangle' /> Bạn đã gửi yêu cầu đăng ký làm chuyên gia. Vui lòng chờ admin phê duyệt. Xin cảm ơn!
														</div>}
													</>}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Copy));
