import _ax from './_ax';

export function getTradingData(){
	return _ax.get('trading-data');
}

export function getBetResult(bet_id){
	return _ax.get('bet-result?bet_id='+bet_id);
}

export function postBet({bet_condition, bet_value}){
	return _ax.post('bet', { bet_condition, bet_value });
}
