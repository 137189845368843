import _ax from './_ax';

export function login(params){
	return _ax.post('login', { ...params });
}

export function register(params){
	return _ax.post('register', { ...params });
}

export function forgotPassword(params){
	return _ax.post('forgot-password', { ...params });
}

export function resetPassword(params){
	return _ax.post('reset-password', { ...params });
}
