import _ax from './_ax';

export function postBecomeExpert(){
	return _ax.post('become-expert');
}

export function postSaveCopyTradeSetting(data){
	return _ax.post('copy-trade-setting', data);
}

export function getExpertInfo(params){
	return _ax.get('expert-info', { params });
}

export function getExpertList(params){
	return _ax.get('expert-list', { params });
}
