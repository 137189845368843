import { Component } from 'react';
import {connect} from "react-redux";
import {logoutUser, fetchingUserSuccess, updateAppConfigAction} from "../redux/actions";
import {withRouter} from 'react-router-dom';
import {
	isCurrentRoute,
	navigateTo,
	setClassNameCondition,
	showNotification,
	isDesktop,
	preventDefault
} from "../lib/helper";
import constants from "../lib/constants";
import api from "../lib/api";

class LeftSidebar extends Component{
	constructor(props) {
		super(props);
		this.state = {
			is_show_setting: false,

			enable_sound: 1,
			is_show_balance: 1
		};
	}

	componentDidMount() {
		let {enable_sound, is_show_balance} = this.props.memberReducer || {};
		this.setState({ enable_sound, is_show_balance });
	}

	onLogout = (e) => {
		e.preventDefault();
		this.props.logoutUser();
		this.props.history.push('login');
	}

	onCloseMenu = (e) => {
		e.preventDefault();
		this.props.updateAppConfigAction({ is_show_menu: false });
	}

	onShowSetting = () => {
		this.setState({ is_show_setting: !this.state.is_show_setting });
	}

	onClickChangeSetting = (type) => () => {
		let value = this.state[type] === 1 ? 0 : 1;
		this.setState({ [type]: value }, () => {
			api.putChangeProfile({ [type]: value })
				.then(({data, success, message}) => {
					if(success){
						return this.props.fetchingUserSuccess(data);
					}
					showNotification({ message, type: success ? 'success' : 'danger' });
				});
		});
	}

	onNavigate = (to) => (e) => {
		this.onCloseMenu(e);
		navigateTo(to).bind(this)(e);
	}

	render() {
		let { window_width, is_show_menu, phone } = this.props.appReducer;
		let {  display_name, avatar } = this.props.memberReducer;
		let { is_show_setting, enable_sound, is_show_balance } = this.state;
		return (
			isDesktop()
				?
			<div data-v-c6d93ce8="" data-v-b0e45e8c="" id="leftSidebar" className="sideBar">
				<div data-v-c6d93ce8="" className="contentSidebar">
					<div data-v-c6d93ce8="" className="topSidebar">
						<ul data-v-c6d93ce8="" className="nav flex-column">
							<li data-v-c6d93ce8="" className="nav-item">
								<a data-v-c6d93ce8="" href="#"
								   onClick={navigateTo('trading').bind(this)}
								   className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.TRADING) ? 'router-link-exact-active router-link-active' : '') }>
									<span data-v-c6d93ce8="">
										<svg data-v-c6d93ce8=""
														xmlns="http://www.w3.org/2000/svg"
														width="19.776" height="20.801"
														viewBox="0 0 19.776 20.801"
														className="non-active"><g data-v-c6d93ce8=""
																				  id="bracket-arrow"
																				  transform="translate(0 21.189) rotate(-90)"><g
								data-v-c6d93ce8="" id="Path_30752" data-name="Path 30752"
								transform="translate(2.447 0)" fill="none" strokeLinecap="round"
								strokeLinejoin="round"><path data-v-c6d93ce8=""
															  d="M9.5,13.385l3.226-3.5L9.5,6.391V1.5l7.743,8.388L9.5,18.276Z"
															  stroke="none" /> <path
								data-v-c6d93ce8=""
								d="M 9.499995231628418 18.2755241394043 L 17.24255561828613 9.887763977050781 L 9.499995231628418 1.500003933906555 L 9.499995231628418 6.390714168548584 L 12.72606468200684 9.887763977050781 L 9.499995231628418 13.38481426239014 L 9.499995231628418 18.2755241394043 M 9.499653816223145 19.77558326721191 C 9.316328048706055 19.77558326721191 9.131294250488281 19.74194717407227 8.953784942626953 19.67254447937012 C 8.378564834594727 19.44763374328613 7.99999475479126 18.89314460754395 7.99999475479126 18.2755241394043 L 7.99999475479126 13.38481426239014 C 7.99999475479126 13.00788402557373 8.141904830932617 12.64477443695068 8.397475242614746 12.3677339553833 L 10.68527698516846 9.887763977050781 L 8.397475242614746 7.407793998718262 C 8.141904830932617 7.130753993988037 7.99999475479126 6.767643928527832 7.99999475479126 6.390714168548584 L 7.99999475479126 1.500003933906555 C 7.99999475479126 0.8823840022087097 8.378564834594727 0.3278939723968506 8.953784942626953 0.1029839739203453 C 9.131258964538574 0.03359496966004372 9.316363334655762 -5.543701263377443e-05 9.499653816223145 -5.543701263377443e-05 C 9.910433769226074 -5.543701263377443e-05 10.31252574920654 0.1687753796577454 10.60219478607178 0.4825839698314667 L 18.34475517272949 8.870344161987305 C 18.87515449523926 9.444933891296387 18.87515449523926 10.33059406280518 18.34475517272949 10.90518379211426 L 10.60219478607178 19.29294395446777 C 10.31255149841309 19.60672569274902 9.910395622253418 19.77558326721191 9.499653816223145 19.77558326721191 Z"
								stroke="none" fill="#fff" /></g> <g data-v-c6d93ce8=""
																	id="Path_30753"
																	data-name="Path 30753"
																	transform="translate(1.389 1.685)"
																	fill="none"
																	strokeLinecap="round"
																	strokeLinejoin="round"><path
								data-v-c6d93ce8=""
								d="M6.687,14.905.5,8.2,6.687,1.5V5.408L4.109,8.2,6.687,11Z"
								stroke="none" /> <path data-v-c6d93ce8=""
													   d="M 6.686956405639648 14.90506172180176 L 6.686956405639648 10.99697208404541 L 4.10905647277832 8.202531814575195 L 6.686956405639648 5.408092021942139 L 6.686956405639648 1.500001788139343 L 0.4999963045120239 8.202531814575195 L 6.686956405639648 14.90506172180176 M 6.687297821044922 16.40512084960938 C 6.276517391204834 16.40512084960938 5.874425888061523 16.23628997802734 5.584756374359131 15.92248153686523 L -0.6022037267684937 9.219951629638672 C -1.132603645324707 8.64536190032959 -1.132603645324707 7.759701728820801 -0.6022037267684937 7.185111999511719 L 5.584756374359131 0.4825818240642548 C 5.87440013885498 0.1688009202480316 6.276555061340332 -5.759948908234946e-05 6.687297821044922 -5.759948908234946e-05 C 6.870623588562012 -5.759948908234946e-05 7.055656909942627 0.03357908129692078 7.233166217803955 0.1029818132519722 C 7.808386325836182 0.3278918266296387 8.186956405639648 0.882381796836853 8.186956405639648 1.500001788139343 L 8.186956405639648 5.408092021942139 C 8.186956405639648 5.785021781921387 8.045045852661133 6.148131847381592 7.78947639465332 6.425171852111816 L 6.149843215942383 8.202531814575195 L 7.78947639465332 9.979891777038574 C 8.045045852661133 10.25693225860596 8.186956405639648 10.620041847229 8.186956405639648 10.99697208404541 L 8.186956405639648 14.90506172180176 C 8.186956405639648 15.52268218994141 7.808386325836182 16.07717132568359 7.233166217803955 16.30208206176758 C 7.055692195892334 16.3714714050293 6.870587825775146 16.40512084960938 6.687297821044922 16.40512084960938 Z"
													   stroke="none"
													   fill="#fff" /></g></g></svg>
										<svg data-v-c6d93ce8=""
											 xmlns="http://www.w3.org/2000/svg"
											 width="19.776"
											 height="20.801" viewBox="0 0 19.776 20.801"
											 className="active"><g
								data-v-c6d93ce8="" id="bracket-arrow"
								transform="translate(0 21.189) rotate(-90)"><g data-v-c6d93ce8=""
																			   id="Path_30752"
																			   data-name="Path 30752"
																			   transform="translate(2.447 0)"
																			   fill="none"
																			   strokeLinecap="round"
																			   strokeLinejoin="round"><path
								data-v-c6d93ce8=""
								d="M9.5,13.385l3.226-3.5L9.5,6.391V1.5l7.743,8.388L9.5,18.276Z"
								stroke="none" /> <path data-v-c6d93ce8=""
													   d="M 9.499995231628418 18.2755241394043 L 17.24255561828613 9.887763977050781 L 9.499995231628418 1.500003933906555 L 9.499995231628418 6.390714168548584 L 12.72606468200684 9.887763977050781 L 9.499995231628418 13.38481426239014 L 9.499995231628418 18.2755241394043 M 9.499653816223145 19.77558326721191 C 9.316328048706055 19.77558326721191 9.131294250488281 19.74194717407227 8.953784942626953 19.67254447937012 C 8.378564834594727 19.44763374328613 7.99999475479126 18.89314460754395 7.99999475479126 18.2755241394043 L 7.99999475479126 13.38481426239014 C 7.99999475479126 13.00788402557373 8.141904830932617 12.64477443695068 8.397475242614746 12.3677339553833 L 10.68527698516846 9.887763977050781 L 8.397475242614746 7.407793998718262 C 8.141904830932617 7.130753993988037 7.99999475479126 6.767643928527832 7.99999475479126 6.390714168548584 L 7.99999475479126 1.500003933906555 C 7.99999475479126 0.8823840022087097 8.378564834594727 0.3278939723968506 8.953784942626953 0.1029839739203453 C 9.131258964538574 0.03359496966004372 9.316363334655762 -5.543701263377443e-05 9.499653816223145 -5.543701263377443e-05 C 9.910433769226074 -5.543701263377443e-05 10.31252574920654 0.1687753796577454 10.60219478607178 0.4825839698314667 L 18.34475517272949 8.870344161987305 C 18.87515449523926 9.444933891296387 18.87515449523926 10.33059406280518 18.34475517272949 10.90518379211426 L 10.60219478607178 19.29294395446777 C 10.31255149841309 19.60672569274902 9.910395622253418 19.77558326721191 9.499653816223145 19.77558326721191 Z"
													   stroke="none" fill="#2076fe" /></g> <g
								data-v-c6d93ce8="" id="Path_30753" data-name="Path 30753"
								transform="translate(1.389 1.685)" fill="none"
								strokeLinecap="round" strokeLinejoin="round"><path
								data-v-c6d93ce8=""
								d="M6.687,14.905.5,8.2,6.687,1.5V5.408L4.109,8.2,6.687,11Z"
								stroke="none" /> <path data-v-c6d93ce8=""
													   d="M 6.686956405639648 14.90506172180176 L 6.686956405639648 10.99697208404541 L 4.10905647277832 8.202531814575195 L 6.686956405639648 5.408092021942139 L 6.686956405639648 1.500001788139343 L 0.4999963045120239 8.202531814575195 L 6.686956405639648 14.90506172180176 M 6.687297821044922 16.40512084960938 C 6.276517391204834 16.40512084960938 5.874425888061523 16.23628997802734 5.584756374359131 15.92248153686523 L -0.6022037267684937 9.219951629638672 C -1.132603645324707 8.64536190032959 -1.132603645324707 7.759701728820801 -0.6022037267684937 7.185111999511719 L 5.584756374359131 0.4825818240642548 C 5.87440013885498 0.1688009202480316 6.276555061340332 -5.759948908234946e-05 6.687297821044922 -5.759948908234946e-05 C 6.870623588562012 -5.759948908234946e-05 7.055656909942627 0.03357908129692078 7.233166217803955 0.1029818132519722 C 7.808386325836182 0.3278918266296387 8.186956405639648 0.882381796836853 8.186956405639648 1.500001788139343 L 8.186956405639648 5.408092021942139 C 8.186956405639648 5.785021781921387 8.045045852661133 6.148131847381592 7.78947639465332 6.425171852111816 L 6.149843215942383 8.202531814575195 L 7.78947639465332 9.979891777038574 C 8.045045852661133 10.25693225860596 8.186956405639648 10.620041847229 8.186956405639648 10.99697208404541 L 8.186956405639648 14.90506172180176 C 8.186956405639648 15.52268218994141 7.808386325836182 16.07717132568359 7.233166217803955 16.30208206176758 C 7.055692195892334 16.3714714050293 6.870587825775146 16.40512084960938 6.687297821044922 16.40512084960938 Z"
													   stroke="none" fill="#2076fe" /></g></g>
										</svg>
									</span>
								<span data-v-c6d93ce8=""
									  className="text-center name color-white-50">Giao dịch</span></a>
							</li>
							<li data-v-c6d93ce8="" className="nav-item">
								<a data-v-c6d93ce8="" onClick={navigateTo('challenge').bind(this)} href="#"
								   className={setClassNameCondition(isCurrentRoute(constants.ROUTE.CHALLENGE),'router-link-exact-active router-link-active','','nav-link')}>
									<span data-v-c6d93ce8="" className="position-relative mb-2">
										<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="18.981" height="20.266" viewBox="0 0 18.981 20.266" className="non-active">
											<g data-v-c6d93ce8="" id="trophy"
											   transform="translate(-1.5 -0.5)"><path
												data-v-c6d93ce8="" id="Path_31718" data-name="Path 31718"
												d="M5.211,6.78H4.569A2.569,2.569,0,0,1,2,4.211V1H5.211" fill="none" stroke="#fff"
												strokeMiterlimit="10" strokeWidth="1"/> <path data-v-c6d93ce8="" id="Path_31719"
																							  data-name="Path 31719"
																							  d="M25,6.78h.642a2.569,2.569,0,0,0,2.569-2.569V1H25"
																							  transform="translate(-8.23)"
																							  fill="none" stroke="#fff"
																							  strokeMiterlimit="10"
																							  strokeWidth="1"/> <line
												data-v-c6d93ce8="" id="Line_1501" data-name="Line 1501" y1="4"
												transform="translate(11 12.571)" fill="none" stroke="#fff" strokeMiterlimit="10"
												strokeWidth="1"/> <path data-v-c6d93ce8="" id="Path_31720" data-name="Path 31720"
																		d="M12.78,13.2h0A5.78,5.78,0,0,1,7,7.422V1H18.559V7.422a5.78,5.78,0,0,1-5.78,5.78Z"
																		transform="translate(-1.789)" fill="none" stroke="#fff"
																		strokeLinecap="square" strokeMiterlimit="10"
																		strokeWidth="1"/> <path data-v-c6d93ce8=""
																								id="Path_31721"
																								data-name="Path 31721"
																								d="M17.991,29.211H9A3.211,3.211,0,0,1,12.211,26H14.78A3.211,3.211,0,0,1,17.991,29.211Z"
																								transform="translate(-2.505 -8.945)"
																								fill="none" stroke="#fff"
																								strokeLinecap="square"
																								strokeMiterlimit="10"
																								strokeWidth="1"/></g>
										</svg>
										<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="18.981" height="20.266" viewBox="0 0 18.981 20.266" className="active"><g data-v-c6d93ce8="" id="trophy"
																																													transform="translate(-1.5 -0.5)"><path
											data-v-c6d93ce8="" id="Path_31718" data-name="Path 31718"
											d="M5.211,6.78H4.569A2.569,2.569,0,0,1,2,4.211V1H5.211" fill="none" stroke="#1790ee"
											strokeMiterlimit="10" strokeWidth="1"/> <path data-v-c6d93ce8="" id="Path_31719"
																						  data-name="Path 31719"
																						  d="M25,6.78h.642a2.569,2.569,0,0,0,2.569-2.569V1H25"
																						  transform="translate(-8.23)"
																						  fill="none" stroke="#1790ee"
																						  strokeMiterlimit="10"
																						  strokeWidth="1"/> <line
											data-v-c6d93ce8="" id="Line_1501" data-name="Line 1501" y1="4"
											transform="translate(11 12.571)" fill="none" stroke="#1790ee" strokeMiterlimit="10"
											strokeWidth="1"/> <path data-v-c6d93ce8="" id="Path_31720" data-name="Path 31720"
																	d="M12.78,13.2h0A5.78,5.78,0,0,1,7,7.422V1H18.559V7.422a5.78,5.78,0,0,1-5.78,5.78Z"
																	transform="translate(-1.789)" fill="none"
																	stroke="#1790ee" strokeLinecap="square"
																	strokeMiterlimit="10" strokeWidth="1"/> <path
											data-v-c6d93ce8="" id="Path_31721" data-name="Path 31721"
											d="M17.991,29.211H9A3.211,3.211,0,0,1,12.211,26H14.78A3.211,3.211,0,0,1,17.991,29.211Z"
											transform="translate(-2.505 -8.945)" fill="none" stroke="#1790ee"
											strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1"/></g>
										</svg>
										<span data-v-c6d93ce8="" className="iconLive pc">
											<span data-v-c6d93ce8="" className="live">
												<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="24.441" height="11.052" viewBox="0 0 24.441 11.052"><g data-v-c6d93ce8="" id="_1246264" data-name="1246264"
																																										 transform="translate(8.061)"><path data-v-c6d93ce8=""
																																																			id="Path_34924"
																																																			data-name="Path 34924"
																																																			d="M.07,43.94c.02.667.767,1.2,1.682,1.2H22.823c.931,0,1.686-.55,1.686-1.228V35.449Zm0,0"
																																																			transform="translate(-8.128 -34.089)"
																																																			fill="#ff3051"/> <path
													data-v-c6d93ce8="" id="Path_34925" data-name="Path 34925"
													d="M24.441,1.228C24.441.55,23.686,0,22.756,0H1.686C.755,0,0,.55,0,1.228v8.6c0,.009,0,.018,0,.027L24.441,1.36Zm0,0"
													transform="translate(-8.061)" fill="#ff4764"/> <g data-v-c6d93ce8=""
																									  id="Group_21085"
																									  data-name="Group 21085"
																									  transform="translate(-1.217 2.985)"><path
													data-v-c6d93ce8="" id="Path_34926" data-name="Path 34926"
													d="M59.16,76.383H56.7V72.274a.362.362,0,0,0-.7,0v4.383a.32.32,0,0,0,.351.274H59.16a.282.282,0,1,0,0-.548Zm0,0"
													transform="translate(-56 -72)" fill="#f1f2f2" stroke="#fff" strokeWidth="0.2"/> <path
													data-v-c6d93ce8="" id="Path_34927" data-name="Path 34927"
													d="M331.16,72.548a.282.282,0,1,0,0-.548h-2.809a.32.32,0,0,0-.351.274v4.383a.32.32,0,0,0,.351.274h2.809a.282.282,0,1,0,0-.548H328.7V74.739h1.755a.282.282,0,1,0,0-.548H328.7V72.548Zm0,0"
													transform="translate(-316.063 -72)" fill="#f1f2f2" stroke="#fff"
													strokeWidth="0.2"/> <path data-v-c6d93ce8="" id="Path_34928"
																			  data-name="Path 34928"
																			  d="M160.351,72a.32.32,0,0,0-.351.274v4.383a.362.362,0,0,0,.7,0V72.274A.32.32,0,0,0,160.351,72Zm0,0"
																			  transform="translate(-155.436 -72)" fill="#f1f2f2"
																			  stroke="#fff" strokeWidth="0.2"/> <path
													data-v-c6d93ce8="" id="Path_34929" data-name="Path 34929"
													d="M204.326,72.007a.443.443,0,0,0-.269.007.315.315,0,0,0-.183.153L202.127,75.8l-1.45-3.622a.378.378,0,0,0-.441-.18c-.185.045-.288.2-.23.344l1.755,4.383a.35.35,0,0,0,.326.192h.01a.359.359,0,0,0,.329-.178l2.107-4.383a.219.219,0,0,0-.009-.21A.331.331,0,0,0,204.326,72.007Zm0,0"
													transform="translate(-193.67 -71.99)" fill="#f1f2f2" stroke="#fff"
													strokeWidth="0.2"/></g> <circle data-v-c6d93ce8="" id="Ellipse_2017"
																					data-name="Ellipse 2017" cx="1.5" cy="1.5"
																					r="1.5" transform="translate(-6.619 3.969)"
																					fill="#fff"/></g></svg>
											</span>
										</span>
									</span>
									<span data-v-c6d93ce8="" className="text-center name color-white-50">Giải đấu</span>
								</a>
							</li>
							<li data-v-c6d93ce8="" className="nav-item">
								<a data-v-c6d93ce8="" onClick={navigateTo('affiliate').bind(this)} href="#"
								   className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.AFFILIATE) ? 'router-link-exact-active router-link-active' : '') }><span
									data-v-c6d93ce8="">
									<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg"
										 xmlnsXlink="http://www.w3.org/1999/xlink" width="21" height="23"
										 viewBox="0 0 21 23" className="non-active">
													<defs data-v-c6d93ce8="">
														<clipPath data-v-c6d93ce8="" id="aff">
															<rect data-v-c6d93ce8="" width="21" height="23"
																  fill="none"/>
														</clipPath>
													</defs>
													<g data-v-c6d93ce8="" id="network-connection" aff="url(#aff)">
														<g data-v-c6d93ce8="" id="network-connection-2"
														   data-name="network-connection"
														   transform="translate(-0.1 0.5)">
															<line data-v-c6d93ce8="" id="Line_1252"
																  data-name="Line 1252" y1="3.29" x2="5.265"
																  transform="translate(7.968 5.855)" fill="none"
																  stroke="#fff" strokeLinecap="round"
																  strokeLinejoin="round" strokeMiterlimit="10"
																  strokeWidth="2"/>
															<line data-v-c6d93ce8="" id="Line_1253"
																  data-name="Line 1253" x2="5.265" y2="3.29"
																  transform="translate(7.968 12.855)" fill="none"
																  stroke="#fff" strokeLinecap="round"
																  strokeLinejoin="round" strokeMiterlimit="10"
																  strokeWidth="2"/>
															<circle data-v-c6d93ce8="" id="Ellipse_1366"
																	data-name="Ellipse 1366" cx="3.5" cy="3.5" r="3.5"
																	transform="translate(13.1 0.5)" strokeWidth="2"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	fill="none"/>
															<circle data-v-c6d93ce8="" id="Ellipse_1367"
																	data-name="Ellipse 1367" cx="3.5" cy="3.5" r="3.5"
																	transform="translate(13.1 14.5)" strokeWidth="2"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	fill="none"/>
															<circle data-v-c6d93ce8="" id="Ellipse_1368"
																	data-name="Ellipse 1368" cx="3.5" cy="3.5" r="3.5"
																	transform="translate(1.1 7.5)" strokeWidth="2"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	fill="none"/>
														</g>
													</g>
												</svg>
									<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg"
										 xmlnsXlink="http://www.w3.org/1999/xlink" width="21" height="23"
										 viewBox="0 0 21 23" className="active">
										<defs data-v-c6d93ce8=""><clipPath
											data-v-c6d93ce8="" id="aff"><rect data-v-c6d93ce8="" width="21" height="23"
																			  fill="none"/></clipPath></defs> <g
										data-v-c6d93ce8="" id="network-connection" aff="url(#aff)"><g data-v-c6d93ce8=""
																									  id="network-connection-2"
																									  data-name="network-connection"
																									  transform="translate(-0.1 0.5)"><line
										data-v-c6d93ce8="" id="Line_1252" data-name="Line 1252" y1="3.29" x2="5.265"
										transform="translate(7.968 5.855)" fill="none" stroke="#2076fe"
										strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
										strokeWidth="2"/> <line data-v-c6d93ce8="" id="Line_1253"
																data-name="Line 1253" x2="5.265" y2="3.29"
																transform="translate(7.968 12.855)" fill="none"
																stroke="#2076fe" strokeLinecap="round"
																strokeLinejoin="round" strokeMiterlimit="10"
																strokeWidth="2"/> <circle
										data-v-c6d93ce8="" id="Ellipse_1366" data-name="Ellipse 1366" cx="3.5" cy="3.5"
										r="3.5" transform="translate(13.1 0.5)" strokeWidth="2" stroke="#2076fe"
										strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
										fill="none"/> <circle data-v-c6d93ce8="" id="Ellipse_1367"
															  data-name="Ellipse 1367" cx="3.5" cy="3.5" r="3.5"
															  transform="translate(13.1 14.5)" strokeWidth="2"
															  stroke="#2076fe" strokeLinecap="round"
															  strokeLinejoin="round" strokeMiterlimit="10"
															  fill="none"/> <circle data-v-c6d93ce8=""
																					id="Ellipse_1368"
																					data-name="Ellipse 1368"
																					cx="3.5" cy="3.5"
																					r="3.5"
																					transform="translate(1.1 7.5)"
																					strokeWidth="2"
																					stroke="#2076fe"
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeMiterlimit="10"
																					fill="none"/></g></g>
									</svg>
								   </span>
									<span data-v-c6d93ce8="" className="text-center name color-white-50">
										Thành Viên VIP
									</span>
								</a>
							</li>

							<li data-v-c6d93ce8="" className="nav-item">
								<a data-v-c6d93ce8="" href='#' onClick={navigateTo('copy-trade').bind(this)} className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.COPY_TRADE) ? 'router-link-exact-active router-link-active' : '') }>
									<span data-v-c6d93ce8="" className='awe-icon fas fa-copy' />
									<span data-v-c6d93ce8="" className="color-white-50">Copy Trade</span>
								</a>
							</li>
							<li data-v-c6d93ce8="" className="nav-item">
								<span data-v-c6d93ce8="" className="line" />
							</li>
							<li data-v-c6d93ce8="" className="nav-item">
								<a data-v-c6d93ce8=""
								   onClick={navigateTo('wallet').bind(this)} href="#"
								   className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.WALLET) ? 'router-link-exact-active router-link-active' : '') }>
									<span
								data-v-c6d93ce8="" className="icon text-center"><svg
								data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="19.815"
								height="16.846" viewBox="0 0 19.815 16.846"
								className="non-active iconWallet"><g data-v-c6d93ce8=""
																	 id="_000000ff"
																	 data-name="#000000ff"
																	 transform="translate(-42.663 -106.619)"><path
								data-v-c6d93ce8="" id="Path_30756" data-name="Path 30756"
								d="M45.072,106.675a3.344,3.344,0,0,1,.674-.054H58.933a5.487,5.487,0,0,1,1.171.061,2.972,2.972,0,0,1,2.374,2.926q0,5.458,0,10.916a2.985,2.985,0,0,1-2.939,2.94H46.391a5.837,5.837,0,0,1-1.532-.1,3,3,0,0,1-2.11-2.16,3.184,3.184,0,0,1-.085-.815q0-5.389,0-10.777a2.946,2.946,0,0,1,.652-1.866,2.985,2.985,0,0,1,1.756-1.066m-.426,2.89q0,5.454,0,10.908a1,1,0,0,0,1.007,1.008H59.49a1,1,0,0,0,1-.96c0-.671.008-1.343,0-2.014-1.494,0-2.988,0-4.482,0a3.467,3.467,0,0,1,.048-6.934H60.5q0-1,0-2.01a.99.99,0,0,0-.957-.961q-6.944,0-13.887,0a1,1,0,0,0-1,.963m9.934,5.193a1.5,1.5,0,0,0,.825,1.627,2.538,2.538,0,0,0,1.159.143c1.311,0,2.622,0,3.933,0,0-.99,0-1.98,0-2.97-1.5,0-2.994,0-4.491,0A1.492,1.492,0,0,0,54.58,114.758Z"
								fill="#fff" /></g></svg> <svg data-v-c6d93ce8=""
																   xmlns="http://www.w3.org/2000/svg"
																   width="19.815" height="16.846"
																   viewBox="0 0 19.815 16.846"
																   className="active iconWallet"><g
								data-v-c6d93ce8="" id="_000000ff" data-name="#000000ff"
								transform="translate(-42.663 -106.619)"><path data-v-c6d93ce8=""
																			  id="Path_30756"
																			  data-name="Path 30756"
																			  d="M45.072,106.675a3.344,3.344,0,0,1,.674-.054H58.933a5.487,5.487,0,0,1,1.171.061,2.972,2.972,0,0,1,2.374,2.926q0,5.458,0,10.916a2.985,2.985,0,0,1-2.939,2.94H46.391a5.837,5.837,0,0,1-1.532-.1,3,3,0,0,1-2.11-2.16,3.184,3.184,0,0,1-.085-.815q0-5.389,0-10.777a2.946,2.946,0,0,1,.652-1.866,2.985,2.985,0,0,1,1.756-1.066m-.426,2.89q0,5.454,0,10.908a1,1,0,0,0,1.007,1.008H59.49a1,1,0,0,0,1-.96c0-.671.008-1.343,0-2.014-1.494,0-2.988,0-4.482,0a3.467,3.467,0,0,1,.048-6.934H60.5q0-1,0-2.01a.99.99,0,0,0-.957-.961q-6.944,0-13.887,0a1,1,0,0,0-1,.963m9.934,5.193a1.5,1.5,0,0,0,.825,1.627,2.538,2.538,0,0,0,1.159.143c1.311,0,2.622,0,3.933,0,0-.99,0-1.98,0-2.97-1.5,0-2.994,0-4.491,0A1.492,1.492,0,0,0,54.58,114.758Z"
																			  fill="#2076fe" /></g></svg></span>
								<span data-v-c6d93ce8=""
									  className="text-center name color-white-50">Ví</span></a></li>
							<li data-v-c6d93ce8="" className="nav-item">
								<a data-v-c6d93ce8="" onClick={navigateTo('dashboard').bind(this)} href="#"
								   className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.DASHBOARD) ? 'router-link-exact-active router-link-active' : '') }><span
									data-v-c6d93ce8=""><svg data-v-c6d93ce8="" id="speedometer"
															xmlns="http://www.w3.org/2000/svg"
															width="17.809" height="17.809"
															viewBox="0 0 17.809 17.809"
															className="non-active"><path
									data-v-c6d93ce8="" id="Path_30761" data-name="Path 30761"
									d="M9.995,9.995a2.226,2.226,0,0,0,0-3.149C9.125,5.978,1,1,1,1S5.978,9.125,6.846,9.995A2.226,2.226,0,0,0,9.995,9.995Z"
									transform="translate(0.484 0.484)" fill="#fff" /> <path
									data-v-c6d93ce8="" id="Path_30762" data-name="Path 30762"
									d="M8.9,0a1.484,1.484,0,1,0,0,2.968A5.936,5.936,0,1,1,2.968,8.9,1.484,1.484,0,1,0,0,8.9,8.9,8.9,0,1,0,8.9,0Z"
									fill="#fff" /></svg> <svg data-v-c6d93ce8="" id="speedometer"
															  xmlns="http://www.w3.org/2000/svg"
															  width="17.809" height="17.809"
															  viewBox="0 0 17.809 17.809"
															  className="active"><path
									data-v-c6d93ce8="" id="Path_30761" data-name="Path 30761"
									d="M9.995,9.995a2.226,2.226,0,0,0,0-3.149C9.125,5.978,1,1,1,1S5.978,9.125,6.846,9.995A2.226,2.226,0,0,0,9.995,9.995Z"
									transform="translate(0.484 0.484)" fill="#2076fe" /> <path
									data-v-c6d93ce8="" id="Path_30762" data-name="Path 30762"
									d="M8.9,0a1.484,1.484,0,1,0,0,2.968A5.936,5.936,0,1,1,2.968,8.9,1.484,1.484,0,1,0,0,8.9,8.9,8.9,0,1,0,8.9,0Z"
									fill="#2076fe" /></svg></span> <span data-v-c6d93ce8=""
																		 className="text-center name color-white-50">Bảng điều khiển</span></a>
							</li>
						</ul>
					</div>
					<div data-v-c6d93ce8="" className="bottomSidebar">
						<ul data-v-c6d93ce8="" className="nav flex-column">
							<li data-v-c6d93ce8="" className="nav-item"><a data-v-c6d93ce8=""
																		   href="#"
																		   onClick={this.onLogout}
																		   className="nav-link borderTop"><span
								data-v-c6d93ce8=""><svg data-v-c6d93ce8=""
														xmlns="http://www.w3.org/2000/svg"
														width="16" height="15" viewBox="0 0 16 15"
														className="mr-2 iconLabel"><g
								data-v-c6d93ce8="" id="log-out" transform="translate(0 -1)"><path
								data-v-c6d93ce8="" id="Path_29008" data-name="Path 29008"
								d="M6.5,5.5v-3a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v12a1,1,0,0,1-1,1h-7a1,1,0,0,1-1-1v-3"
								fill="none" stroke="#fff" strokeLinecap="round"
								strokeLinejoin="round" strokeMiterlimit="10"
								strokeWidth="1" /> <line data-v-c6d93ce8="" id="Line_1254"
															   data-name="Line 1254" x1="11"
															   transform="translate(0.5 8.5)"
															   fill="none" stroke="#fff"
															   strokeLinecap="round"
															   strokeLinejoin="round"
															   strokeMiterlimit="10"
															   strokeWidth="1" /> <path
								data-v-c6d93ce8="" id="Path_29009" data-name="Path 29009"
								d="M3.5,5.5l-3,3,3,3" fill="none" stroke="#fff"
								strokeLinecap="round" strokeLinejoin="round"
								strokeMiterlimit="10" strokeWidth="1" /></g></svg></span>
								<span data-v-c6d93ce8="" className="color-white-50">Đăng xuất</span></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			:
				<div data-v-b0e45e8c="" className="mobile">
					<div data-v-9d166468="" data-v-b0e45e8c="" id="leftSidebar"
						 className={'sidebarMore anim mobile ' + (is_show_menu ? 'active' : 'inActive')}>
						<div data-v-9d166468="" className="menuProfile">
							<div data-v-9d166468="" className="bodySidebar">
								<a data-v-211ca04e="" href="#" onClick={this.onCloseMenu} className="swapMenu close-side-bar-mobile">
									<i className='fas fa-times' />
								</a>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4 pt-3">
									<b data-v-9d166468="">Kiếm tiền</b>
								</h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu"><a data-v-9d166468=""
																				   href="#"
																				   onClick={this.onNavigate('trading')}
																				   className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.TRADING) ? 'router-link-exact-active router-link-active' : '') }>
										<div data-v-9d166468="" className="leftNav">
											<svg data-v-9d166468="" xmlns="http://www.w3.org/2000/svg"
												 width="19.776" height="20.801"
												 viewBox="0 0 19.776 20.801">
												<g data-v-9d166468="" id="bracket-arrow"
												   transform="translate(0 21.189) rotate(-90)">
													<g data-v-9d166468="" id="Path_30752"
													   data-name="Path 30752"
													   transform="translate(2.447 0)" fill="none"
													   strokeLinecap="round" strokeLinejoin="round">
														<path data-v-9d166468=""
															  d="M9.5,13.385l3.226-3.5L9.5,6.391V1.5l7.743,8.388L9.5,18.276Z"
															  stroke="none"/>
														<path data-v-9d166468=""
															  d="M 9.499995231628418 18.2755241394043 L 17.24255561828613 9.887763977050781 L 9.499995231628418 1.500003933906555 L 9.499995231628418 6.390714168548584 L 12.72606468200684 9.887763977050781 L 9.499995231628418 13.38481426239014 L 9.499995231628418 18.2755241394043 M 9.499653816223145 19.77558326721191 C 9.316328048706055 19.77558326721191 9.131294250488281 19.74194717407227 8.953784942626953 19.67254447937012 C 8.378564834594727 19.44763374328613 7.99999475479126 18.89314460754395 7.99999475479126 18.2755241394043 L 7.99999475479126 13.38481426239014 C 7.99999475479126 13.00788402557373 8.141904830932617 12.64477443695068 8.397475242614746 12.3677339553833 L 10.68527698516846 9.887763977050781 L 8.397475242614746 7.407793998718262 C 8.141904830932617 7.130753993988037 7.99999475479126 6.767643928527832 7.99999475479126 6.390714168548584 L 7.99999475479126 1.500003933906555 C 7.99999475479126 0.8823840022087097 8.378564834594727 0.3278939723968506 8.953784942626953 0.1029839739203453 C 9.131258964538574 0.03359496966004372 9.316363334655762 -5.543701263377443e-05 9.499653816223145 -5.543701263377443e-05 C 9.910433769226074 -5.543701263377443e-05 10.31252574920654 0.1687753796577454 10.60219478607178 0.4825839698314667 L 18.34475517272949 8.870344161987305 C 18.87515449523926 9.444933891296387 18.87515449523926 10.33059406280518 18.34475517272949 10.90518379211426 L 10.60219478607178 19.29294395446777 C 10.31255149841309 19.60672569274902 9.910395622253418 19.77558326721191 9.499653816223145 19.77558326721191 Z"
															  stroke="none" fill="#fff" />
													</g>
													<g data-v-9d166468="" id="Path_30753"
													   data-name="Path 30753"
													   transform="translate(1.389 1.685)" fill="none"
													   strokeLinecap="round" strokeLinejoin="round">
														<path data-v-9d166468=""
															  d="M6.687,14.905.5,8.2,6.687,1.5V5.408L4.109,8.2,6.687,11Z"
															  stroke="none"/>
														<path data-v-9d166468=""
															  d="M 6.686956405639648 14.90506172180176 L 6.686956405639648 10.99697208404541 L 4.10905647277832 8.202531814575195 L 6.686956405639648 5.408092021942139 L 6.686956405639648 1.500001788139343 L 0.4999963045120239 8.202531814575195 L 6.686956405639648 14.90506172180176 M 6.687297821044922 16.40512084960938 C 6.276517391204834 16.40512084960938 5.874425888061523 16.23628997802734 5.584756374359131 15.92248153686523 L -0.6022037267684937 9.219951629638672 C -1.132603645324707 8.64536190032959 -1.132603645324707 7.759701728820801 -0.6022037267684937 7.185111999511719 L 5.584756374359131 0.4825818240642548 C 5.87440013885498 0.1688009202480316 6.276555061340332 -5.759948908234946e-05 6.687297821044922 -5.759948908234946e-05 C 6.870623588562012 -5.759948908234946e-05 7.055656909942627 0.03357908129692078 7.233166217803955 0.1029818132519722 C 7.808386325836182 0.3278918266296387 8.186956405639648 0.882381796836853 8.186956405639648 1.500001788139343 L 8.186956405639648 5.408092021942139 C 8.186956405639648 5.785021781921387 8.045045852661133 6.148131847381592 7.78947639465332 6.425171852111816 L 6.149843215942383 8.202531814575195 L 7.78947639465332 9.979891777038574 C 8.045045852661133 10.25693225860596 8.186956405639648 10.620041847229 8.186956405639648 10.99697208404541 L 8.186956405639648 14.90506172180176 C 8.186956405639648 15.52268218994141 7.808386325836182 16.07717132568359 7.233166217803955 16.30208206176758 C 7.055692195892334 16.3714714050293 6.870587825775146 16.40512084960938 6.687297821044922 16.40512084960938 Z"
															  stroke="none" fill="#fff"/>
													</g>
												</g>
											</svg>
										</div>
										<div data-v-9d166468="" className="rightNav">
											Giao dịch
										</div>
									</a></li>
									<li data-v-9d166468="" className="swapMenu">
										<div data-v-9d166468="" onClick={this.onNavigate('affiliate')} className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.AFFILIATE) ? 'router-link-exact-active router-link-active' : '') }>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg"
													 xmlnsXlink="http://www.w3.org/1999/xlink" width="21" height="23"
													 viewBox="0 0 21 23" className="non-active">
													<defs data-v-c6d93ce8="">
														<clipPath data-v-c6d93ce8="" id="aff">
															<rect data-v-c6d93ce8="" width="21" height="23"
																  fill="none"/>
														</clipPath>
													</defs>
													<g data-v-c6d93ce8="" id="network-connection" aff="url(#aff)">
														<g data-v-c6d93ce8="" id="network-connection-2"
														   data-name="network-connection"
														   transform="translate(-0.1 0.5)">
															<line data-v-c6d93ce8="" id="Line_1252"
																  data-name="Line 1252" y1="3.29" x2="5.265"
																  transform="translate(7.968 5.855)" fill="none"
																  stroke="#fff" strokeLinecap="round"
																  strokeLinejoin="round" strokeMiterlimit="10"
																  strokeWidth="2"/>
															<line data-v-c6d93ce8="" id="Line_1253"
																  data-name="Line 1253" x2="5.265" y2="3.29"
																  transform="translate(7.968 12.855)" fill="none"
																  stroke="#fff" strokeLinecap="round"
																  strokeLinejoin="round" strokeMiterlimit="10"
																  strokeWidth="2"/>
															<circle data-v-c6d93ce8="" id="Ellipse_1366"
																	data-name="Ellipse 1366" cx="3.5" cy="3.5" r="3.5"
																	transform="translate(13.1 0.5)" strokeWidth="2"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	fill="none"/>
															<circle data-v-c6d93ce8="" id="Ellipse_1367"
																	data-name="Ellipse 1367" cx="3.5" cy="3.5" r="3.5"
																	transform="translate(13.1 14.5)" strokeWidth="2"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	fill="none"/>
															<circle data-v-c6d93ce8="" id="Ellipse_1368"
																	data-name="Ellipse 1368" cx="3.5" cy="3.5" r="3.5"
																	transform="translate(1.1 7.5)" strokeWidth="2"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	fill="none"/>
														</g>
													</g>
												</svg>
											</div>
											<div data-v-9d166468=""
												 className="rightNav  position-relative"><span
												data-v-9d166468="">Thành Viên VIP</span> <span data-v-9d166468=""
																							   className="totalCount text-uppercase"
																							   style={{display: 'none'}}>0</span>
											</div>
										</div>
									</li>
								</ul>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4">
									<b data-v-9d166468="">All Events</b>
								</h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" onClick={this.onNavigate('challenge')} className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.CHALLENGE) ? 'router-link-exact-active router-link-active' : '') }>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-211ca04e="" xmlns="http://www.w3.org/2000/svg" width="18.981" height="20.266" viewBox="0 0 18.981 20.266"><g data-v-211ca04e="" id="trophy" transform="translate(-1.5 -0.5)"><path data-v-211ca04e="" id="Path_31718" data-name="Path 31718" d="M5.211,6.78H4.569A2.569,2.569,0,0,1,2,4.211V1H5.211" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/> <path data-v-211ca04e="" id="Path_31719" data-name="Path 31719" d="M25,6.78h.642a2.569,2.569,0,0,0,2.569-2.569V1H25" transform="translate(-8.23)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/> <line data-v-211ca04e="" id="Line_1501" data-name="Line 1501" y1="4" transform="translate(11 12.571)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/> <path data-v-211ca04e="" id="Path_31720" data-name="Path 31720" d="M12.78,13.2h0A5.78,5.78,0,0,1,7,7.422V1H18.559V7.422a5.78,5.78,0,0,1-5.78,5.78Z" transform="translate(-1.789)" fill="none" stroke="#fff" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1"/> <path data-v-211ca04e="" id="Path_31721" data-name="Path 31721" d="M17.991,29.211H9A3.211,3.211,0,0,1,12.211,26H14.78A3.211,3.211,0,0,1,17.991,29.211Z" transform="translate(-2.505 -8.945)" fill="none" stroke="#fff" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" /></g></svg>
											</div>
											<div data-v-9d166468="" className="rightNav">
												Giải Đấu
											</div>
										</a>
									</li>
								</ul>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4">
									<b data-v-9d166468="">Quản lý hồ sơ</b>
								</h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu"><a data-v-9d166468=""
																				   href="#"
																				   onClick={this.onNavigate('profile')}
																				   className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.PROFILE) ? 'router-link-exact-active router-link-active' : '') } >
										<div data-v-9d166468=""
											 className="leftNav labelIcon d-flex align-items-center">
											<div data-v-9d166468="" className="avatar user-avatar" style={{ backgroundImage: `url(${avatar || 'images/user.png'})` }}>
												<div data-v-9d166468="" className="overlay"/>
											</div>
										</div>
										<span data-v-9d166468=""
											  className="rightNav textNickName">{display_name}</span></a>
									</li>
									<li data-v-9d166468="" className="swapMenu">
										<div data-v-9d166468="" onClick={this.onNavigate('wallet')} className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.WALLET) ? 'router-link-exact-active router-link-active' : '') }>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-211ca04e="" xmlns="http://www.w3.org/2000/svg"
													 width="19.815" height="16.846" viewBox="0 0 19.815 16.846"
													 className="iconWallet">
													<g data-v-211ca04e="" id="_000000ff" data-name="#000000ff"
													   transform="translate(-42.663 -106.619)">
														<path data-v-211ca04e="" id="Path_30756" data-name="Path 30756"
															  d="M45.072,106.675a3.344,3.344,0,0,1,.674-.054H58.933a5.487,5.487,0,0,1,1.171.061,2.972,2.972,0,0,1,2.374,2.926q0,5.458,0,10.916a2.985,2.985,0,0,1-2.939,2.94H46.391a5.837,5.837,0,0,1-1.532-.1,3,3,0,0,1-2.11-2.16,3.184,3.184,0,0,1-.085-.815q0-5.389,0-10.777a2.946,2.946,0,0,1,.652-1.866,2.985,2.985,0,0,1,1.756-1.066m-.426,2.89q0,5.454,0,10.908a1,1,0,0,0,1.007,1.008H59.49a1,1,0,0,0,1-.96c0-.671.008-1.343,0-2.014-1.494,0-2.988,0-4.482,0a3.467,3.467,0,0,1,.048-6.934H60.5q0-1,0-2.01a.99.99,0,0,0-.957-.961q-6.944,0-13.887,0a1,1,0,0,0-1,.963m9.934,5.193a1.5,1.5,0,0,0,.825,1.627,2.538,2.538,0,0,0,1.159.143c1.311,0,2.622,0,3.933,0,0-.99,0-1.98,0-2.97-1.5,0-2.994,0-4.491,0A1.492,1.492,0,0,0,54.58,114.758Z"
															  fill="#fff"/>
													</g>
												</svg>
											</div>
											<div data-v-9d166468=""
												 className="rightNav  position-relative"><span
												data-v-9d166468="">Ví</span> <span data-v-9d166468=""
																				   className="totalCount text-uppercase"
																				   style={{display: 'none'}}>0</span>
											</div>
										</div>
									</li>
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" href='#' onClick={navigateTo('copy-trade').bind(this)} className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.COPY_TRADE) ? 'router-link-exact-active router-link-active' : '') }>
											<div data-v-9d166468="" className="leftNav aws-icon fas fa-copy" />
											<div data-v-9d166468="" className="rightNav text-capitalize d-flex">
												Copy Trade
											</div>
										</a>
									</li>
									{/*<li data-v-9d166468="" className="swapMenu">*/}
									{/*	<a data-v-9d166468="" href="#" onClick={preventDefault} className={'nav-link'}>*/}
									{/*		<div data-v-9d166468="" className="leftNav">*/}
									{/*			<svg data-v-211ca04e="" xmlns="http://www.w3.org/2000/svg" width="22.633" height="21.223" viewBox="0 0 22.633 21.223"><g data-v-211ca04e="" id="transfer" transform="translate(-25.599 -35.35) rotate(-30)"><g data-v-211ca04e="" id="Group_13372" data-name="Group 13372" transform="translate(0 51.198)"><path data-v-211ca04e="" id="Path_30757" data-name="Path 30757" d="M17.79,55.881,13.3,51.386a.642.642,0,0,0-1.1.454v1.926H7.7a.642.642,0,0,0,0,1.284h5.137a.642.642,0,0,0,.642-.642V53.39l2.945,2.945L13.484,59.28V58.261a.642.642,0,0,0-.642-.642H5.778V55.693a.642.642,0,0,0-1.1-.454L.188,59.734a.642.642,0,0,0,0,.908l4.495,4.495a.642.642,0,0,0,.454.188.634.634,0,0,0,.246-.049.642.642,0,0,0,.4-.593V62.756h4.495a.642.642,0,1,0,0-1.284H5.136a.642.642,0,0,0-.642.642v1.018L1.55,60.187l2.945-2.945v1.018a.642.642,0,0,0,.642.642H12.2V60.83a.642.642,0,0,0,1.1.454l4.495-4.495A.642.642,0,0,0,17.79,55.881Z" transform="translate(0 -51.198)" fill="#fff"/></g></g></svg>*/}
									{/*		</div>*/}
									{/*		<div data-v-9d166468="" className="rightNav text-capitalize d-flex">*/}
									{/*			Lệnh*/}
									{/*		</div>*/}
									{/*	</a>*/}
									{/*</li>*/}
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" onClick={this.onNavigate('dashboard')} className={ 'nav-link ' + (isCurrentRoute(constants.ROUTE.DASHBOARD) ? 'router-link-exact-active router-link-active' : '') }>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-9d166468="" id="speedometer"
													 xmlns="http://www.w3.org/2000/svg" width="17.809"
													 height="17.809" viewBox="0 0 17.809 17.809">
													<path data-v-9d166468="" id="Path_30761"
														  data-name="Path 30761"
														  d="M9.995,9.995a2.226,2.226,0,0,0,0-3.149C9.125,5.978,1,1,1,1S5.978,9.125,6.846,9.995A2.226,2.226,0,0,0,9.995,9.995Z"
														  transform="translate(0.484 0.484)"
														  fill="#fff"/>
													<path data-v-9d166468="" id="Path_30762"
														  data-name="Path 30762"
														  d="M8.9,0a1.484,1.484,0,1,0,0,2.968A5.936,5.936,0,1,1,2.968,8.9,1.484,1.484,0,1,0,0,8.9,8.9,8.9,0,1,0,8.9,0Z"
														  fill="#fff"/>
												</svg>
											</div>
											<div data-v-9d166468="" className="rightNav">
												Bảng Điều Khiển
											</div>
										</a>
									</li>
								</ul>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4"><b
									data-v-9d166468="">Cài đặt &amp; Trợ giúp</b></h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu help mt-auto"><a
										data-v-9d166468="" href={phone} target='_blank'
										className="pointer nav-link d-flex align-items-center">
										<div data-v-9d166468="" className="leftNav">
											<svg data-v-9d166468="" xmlns="http://www.w3.org/2000/svg"
												 width="17.844" height="19.428"
												 viewBox="0 0 17.844 19.428">
												<g data-v-9d166468="" id="headset"
												   transform="translate(-3 -1)">
													<path data-v-9d166468="" id="Path_29575"
														  data-name="Path 29575"
														  d="M28,38.753h3.961a2.376,2.376,0,0,0,2.377-2.377V34"
														  transform="translate(-14.494 -19.325)"
														  fill="none" stroke="#fff"
														  strokeLinecap="square" strokeMiterlimit="10"
														  strokeWidth="2"/>
													<path data-v-9d166468="" id="Path_29576"
														  data-name="Path 29576"
														  d="M7.961,24H4v3.961a1.584,1.584,0,0,0,1.584,1.584H7.961Z"
														  transform="translate(0 -13.286)" fill="none"
														  stroke="#fff" strokeLinecap="square"
														  strokeMiterlimit="10"
														  strokeWidth="2"/>
													<path data-v-9d166468="" id="Path_29577"
														  data-name="Path 29577"
														  d="M37.961,24H34v5.545h2.377a1.584,1.584,0,0,0,1.584-1.584Z"
														  transform="translate(-18.117 -13.286)"
														  fill="none" stroke="#fff"
														  strokeLinecap="square" strokeMiterlimit="10"
														  strokeWidth="2"/>
													<path data-v-9d166468="" id="Path_29578"
														  data-name="Path 29578"
														  d="M19.844,10.714V9.922A7.922,7.922,0,0,0,11.922,2h0A7.922,7.922,0,0,0,4,9.922v.792"
														  fill="none" stroke="#fff"
														  strokeLinecap="square" strokeMiterlimit="10"
														  strokeWidth="2"/>
												</g>
											</svg>
										</div>
										<div data-v-9d166468="" className="rightNav text-capitalize">Trợ giúp</div>
									</a>
									</li>
									<li data-v-9d166468="" className="settings">
										<div data-v-9d166468=""
											 onClick={this.onShowSetting}
											 className="pointer nav-link d-flex align-items-center mobile">
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-9d166468=""
													 xmlns="http://www.w3.org/2000/svg" width="16"
													 height="16" viewBox="0 0 16 16">
													<g data-v-9d166468="" id="gear"
													   transform="translate(-2.004 -1.995)">
														<path data-v-9d166468="" id="Path_30727"
															  data-name="Path 30727"
															  d="M18.111,9.362,16.772,9.25a6.245,6.245,0,0,0-.814-1.96l.871-1.03a1.28,1.28,0,0,0-1.8-1.8L14,5.326a6.234,6.234,0,0,0-1.963-.816L11.92,3.17a1.28,1.28,0,0,0-2.552,0L9.256,4.51a6.245,6.245,0,0,0-1.96.814l-1.03-.868a1.28,1.28,0,0,0-1.8,1.8l.871,1.029a6.239,6.239,0,0,0-.816,1.961l-1.341.112a1.281,1.281,0,0,0,0,2.552l1.341.112a6.238,6.238,0,0,0,.814,1.96l-.871,1.03a1.28,1.28,0,0,0,1.8,1.8L7.3,15.95a6.239,6.239,0,0,0,1.961.816l.112,1.341a1.281,1.281,0,0,0,2.552,0l.112-1.341a6.238,6.238,0,0,0,1.96-.814l1.03.871a1.28,1.28,0,0,0,1.8-1.8l-.871-1.029a6.233,6.233,0,0,0,.816-1.961l1.341-.112a1.281,1.281,0,0,0,0-2.552Zm-7.467,4.445a3.168,3.168,0,1,1,3.168-3.168A3.168,3.168,0,0,1,10.644,13.806Z"
															  fill="#fff"/>
													</g>
												</svg>
											</div>
											<div data-v-9d166468="" className="rightNav colorWhite">Cài đặt</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div>
							{is_show_setting && <div className='settings'>
								<div data-v-c80df956="" className={'dropdown-menu dropdownSetting d-flex flex-column show'}>
									<div data-v-c80df956="" className="header">
										<h4 data-v-c80df956="" className="colorWhite">Cài đặt</h4>
										<button data-v-c80df956="" type="button" className="close" onClick={this.onShowSetting}>
											<svg data-v-c80df956="" xmlns="http://www.w3.org/2000/svg"
												 viewBox="0 0 32 32">
												<g data-v-c80df956="" strokeLinecap="square"
												   strokeLinejoin="miter" strokeWidth="2"
												   fill="currentColor" stroke="currentColor"
												   className="nc-icon-wrapper">
													<g data-v-c80df956=""
													   className="nc-interact_menu-close-2-o-32">
														<path data-v-c80df956="" fill="currentColor"
															  stroke="currentColor" strokeMiterlimit="10"
															  d="M2 6h28"
															  transform="translate(0 10.00) rotate(45.00 16 6)"/>
														<path data-v-c80df956="" data-color="color-2"
															  fill="currentColor" strokeMiterlimit="10"
															  d="M2 16h28" opacity="0"/>
														<path data-v-c80df956="" fill="currentColor"
															  stroke="currentColor" strokeMiterlimit="10"
															  d="M2 26h28"
															  transform="translate(0 -10) rotate(-45 16 26)"/>
													</g>
												</g>
											</svg>
										</button>
									</div>
									<div data-v-c80df956="" className="wrap setting-mobile">
										<div data-v-c80df956="" className="line mb-1">
											<div data-v-c80df956="" className="type">
												<svg data-v-c80df956="" version="1.1" width="19" height="19" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19 19" xmlSpace="preserve"><g data-v-c80df956="" id="sound" transform="translate(0.5 -0.5)"><g data-v-c80df956="" id="Path_30736" fill="#887F9E"><path data-v-c80df956="" d="M13.7,19.3c-0.2,0-0.4-0.1-0.6-0.2l-6.9-4.6H0.5c-0.6,0-1-0.4-1-1V6.3c0-0.6,0.4-1,1-1h5.7l6.9-4.6
                                        c0.3-0.2,0.7-0.2,1,0c0.3,0.2,0.5,0.5,0.5,0.9v16.8c0,0.4-0.2,0.7-0.5,0.9C14,19.3,13.9,19.3,13.7,19.3z M1.5,12.5h5
                                        c0.2,0,0.4,0.1,0.6,0.2l5.6,3.8V3.4L7.1,7.1C6.9,7.2,6.7,7.3,6.5,7.3h-5V12.5z" className="st0"/></g> <g
													data-v-c80df956="" id="Path_30737" fill="#887F9E"><path data-v-c80df956="" d="M16.8,15.1c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c1.9-2,1.9-5.1-0.1-7
                                        c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0c2.8,2.7,2.8,7.1,0.1,9.9c0,0-0.1,0.1-0.1,0.1C17.2,15,17,15.1,16.8,15.1z" className="st0" /></g></g></svg>
												<span data-v-c80df956=""
													  className="colorWhite">Âm thanh</span></div>
											<div data-v-c80df956="" className="control">
												<div data-v-272fdeda="" data-v-c80df956=""
													 className="toggleButtonWrapper"><span
													data-v-272fdeda="" className="colorWhite">Off</span>
													<div data-v-272fdeda=""
														 onClick={this.onClickChangeSetting('enable_sound')} className={'toggleButton pointer isButtonOnOff ' + (enable_sound ? 'hasBg' : '') }>
														<div data-v-272fdeda=""
															 className={'status ' + (enable_sound ? 'yes' : '')}/>
													</div>
													<span data-v-272fdeda=""
														  className="colorWhite">On</span></div>
											</div>
										</div>
										<div data-v-c80df956="" className="line">
											<div data-v-c80df956="" className="type">
												<svg data-v-c80df956="" xmlns="http://www.w3.org/2000/svg"
													 width="18" height="13.543" viewBox="0 0 18 13.543">
													<g data-v-c80df956="" id="preview"
													   transform="translate(-734.025 -2)" opacity="0.5">
														<path data-v-c80df956="" id="Path_30402"
															  data-name="Path 30402"
															  d="M9.025,15.543c4.063,0,7.223-3.5,8.577-5.53a2.2,2.2,0,0,0,0-2.6C16.248,5.5,13.088,2,9.025,2S1.8,5.5.448,7.53a2.031,2.031,0,0,0,0,2.483C1.8,12.044,4.962,15.543,9.025,15.543Zm0-10.157a3.325,3.325,0,0,1,3.386,3.386,3.325,3.325,0,0,1-3.386,3.386A3.325,3.325,0,0,1,5.64,8.771,3.325,3.325,0,0,1,9.025,5.386Z"
															  transform="translate(734)" fill="#fff"/>
													</g>
												</svg>
												<span data-v-c80df956="" className="colorWhite">Hiện số dư
                            </span></div>
											<div data-v-c80df956="" className="control">
												<div data-v-272fdeda="" data-v-c80df956=""
													 className="toggleButtonWrapper"><span
													data-v-272fdeda="" className="colorWhite">Off</span>
													<div data-v-272fdeda=""
														 onClick={this.onClickChangeSetting('is_show_balance')}
														 className={'toggleButton pointer isButtonOnOff ' + (is_show_balance ? 'hasBg' : '') }>
														<div data-v-272fdeda=""
															 className={'status ' + (is_show_balance ? 'yes' : '')}/>
													</div>
													<span data-v-272fdeda=""
														  className="colorWhite">On</span></div>
											</div>
										</div>
									</div>
									<button data-v-c80df956=""
											style={{ paddingLeft: '0.75rem' }}
											onClick={this.onLogout}
											className="button justify-content-start wbtn btn-large w-100 mt-auto">
										<svg data-v-c80df956="" xmlns="http://www.w3.org/2000/svg"
											 width="17" height="16" viewBox="0 0 17 16" className="mr-2">
											<g data-v-c80df956="" id="log-out"
											   transform="translate(0.5 -0.5)">
												<path data-v-c80df956="" id="Path_29008"
													  data-name="Path 29008"
													  d="M6.5,5.5v-3a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v12a1,1,0,0,1-1,1h-7a1,1,0,0,1-1-1v-3"
													  fill="none" stroke="#fff" strokeLinecap="round"
													  strokeLinejoin="round" strokeMiterlimit="10"
													  strokeWidth="2"/>
												<line data-v-c80df956="" id="Line_1254"
													  data-name="Line 1254" x1="11"
													  transform="translate(0.5 8.5)" fill="none"
													  stroke="#fff" strokeLinecap="round"
													  strokeLinejoin="round" strokeMiterlimit="10"
													  strokeWidth="2"/>
												<path data-v-c80df956="" id="Path_29009"
													  data-name="Path 29009" d="M3.5,5.5l-3,3,3,3"
													  fill="none" stroke="#fff" strokeLinecap="round"
													  strokeLinejoin="round" strokeMiterlimit="10"
													  strokeWidth="2"/>
											</g>
										</svg>
										<span data-v-c80df956="">Đăng xuất</span></button>
								</div>
							</div>}
						</div>
					</div>
					{is_show_menu && !is_show_setting && <div className='close-bar' onClick={this.onCloseMenu} />}
				</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		appReducer: state.appReducer,
		memberReducer: state.memberReducer
	};
}
export default connect(mapStateToProps, {
	logoutUser,
	fetchingUserSuccess,
	updateAppConfigAction
})(withRouter(LeftSidebar));
