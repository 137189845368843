import constants from "./constants";
import {Store} from "react-notifications-component";

export function getDeviceVersion(){
	return window.innerWidth > 1024 ? 'pc' : window.innerWidth <= 1024 && window.innerWidth >= 576 ? window.innerHeight < 576 ? 'mobile-landscape' :
		'tablet' : 90 === window.orientation ? 'mobile-landscape' : 'mobile';
}

export function formatDecimalNotRound(t){
	var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : ""
		, n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 6
		, a = !(arguments.length > 3 && void 0 !== arguments[3]) || arguments[3]
		, i = parseFloat(t.toString().replace(",", "") || 0).toFixed(8)
		, o = ("" + ((i = parseFloat(i)) || e)).split(".");
	if (1 === o.length) {
		if (a) {
			for (var r = "", s = 0; s < n; s++)
				r += "0";
			return o[0] ? o[0] + "." + r : Number(o[0])
		}
		return Number(o[0])
	}
	if (a) {
		if (o[1].length < n) {
			for (var l = n - o[1].length, d = 0; d < l; d++)
				o[1] = o[1] + "0";
			return o[0] + "." + o[1].substr(0, n)
		}
		return o[0] + "." + o[1].substr(0, n)
	}
	return Number(o[0] + "." + o[1].substr(0, n))
}

export function getDeviceInfo(){
	return {
		deviceHeight: window.innerHeight,
		deviceWidth: window.innerWidth,
		deviceVersion: getDeviceVersion()
	};
}

export function getCurrentRoute(){
	let pathname = window.location.pathname || '';
	if(pathname.includes('register')){
		return constants.ROUTE.REGISTER;
	}
	if(pathname.includes('login')){
		return constants.ROUTE.LOGIN;
	}
	if(pathname.includes('forgot-password')){
		return constants.ROUTE.FORGOT_PASSWORD;
	}
	if(pathname.includes('trading')){
		return constants.ROUTE.TRADING;
	}
	if(pathname.includes('wallet')){
		return constants.ROUTE.WALLET;
	}
	if(pathname.includes('profile')){
		return constants.ROUTE.PROFILE;
	}
	if(pathname.includes('dashboard')){
		return constants.ROUTE.DASHBOARD;
	}
	if(pathname.includes('affiliate')){
		return constants.ROUTE.AFFILIATE;
	}
	if(pathname.includes('deposit')){
		return constants.ROUTE.DEPOSIT;
	}
	if(pathname.includes('transfer')){
		return constants.ROUTE.TRANSFER;
	}
	if(pathname.includes('challenge')){
		return constants.ROUTE.CHALLENGE;
	}
	if(pathname.includes('copy-trade')){
		return constants.ROUTE.COPY_TRADE;
	}
	return constants.ROUTE.HOME;
}

export function isCurrentRoute(route){
	return getCurrentRoute() === route;
}

export function getCurrentWrapperClass(){
	switch (getCurrentRoute()){
		case constants.ROUTE.REGISTER: return 'Register';
		case constants.ROUTE.LOGIN: return 'Login';
		case constants.ROUTE.FORGOT_PASSWORD: return 'Forgot Password';
		case constants.ROUTE.PROFILE: return 'Profile';
		case constants.ROUTE.DASHBOARD: return 'trade_history';
		case constants.ROUTE.TRADING: return 'index';
		case constants.ROUTE.WALLET: return 'binary_wallet';
		case constants.ROUTE.AFFILIATE: return 'affiliate';
		case constants.ROUTE.CHALLENGE: return 'challenge';
		case constants.ROUTE.TRANSFER: return 'transfer';
		case constants.ROUTE.COPY_TRADE: return 'copy trade';
		default: return 'Home';
	}
}

export function formatNumber(value, ignore_00 = false){
	if(!ignore_00){
		value = value ? parseFloat(value).toFixed(2) : '0';
	}
	return (value || '').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function showNotification(options){
	let durationDismiss = options?.dismiss?.duration || (options.message || '').length * 100;
	if(durationDismiss < 5000){
		durationDismiss = 5000;
	}
	if(durationDismiss > 60000){
		durationDismiss = 60000;
	}
	return Store.addNotification({
		message: 'Nội dung...',
		type: "success",
		insert: "top",
		container: "top-right",
		animationIn: ["animate__animated", "animate__fadeIn"],
		animationOut: ["animate__animated", "animate__fadeOut"],
		dismiss: {
			duration: durationDismiss,
			onScreen: true,
			showIcon: true,
		},
		...options
	});
}

export const navigateTo = (to, params = {}) => function (e){
	if(!to){
		to = '/';
	}
	if(!to.startsWith('/')){
		to = '/' + to;
	}
	e && e.preventDefault && e.preventDefault();
	this.props.history.push(to, params);
}

export async function readURL(files) {
	return new Promise(resolve => {
		if (files && files[0]) {
			let reader = new FileReader();
			reader.onload = e =>  resolve({
				data: e.target.result,
				size: files[0].size
			});
			reader.readAsDataURL(files[0]);
		} else {
			resolve(false);
		}
	})
}

export function setClassNameCondition(ifTrue, classTrue, classFalse, initClass = ''){
	return initClass + ' ' + (ifTrue ? classTrue : classFalse);
}

export function getUrl(pathname){
	let { hostname, protocol, port } = window.location;
	let portString = '';
	if(port !== '80' && port !== '443' && port !== ''){
		portString = ':' + port;
	}
	return protocol + '//' + hostname + portString + '/' + pathname;
}

export function getJSONFromUrl(){
	try{
		let search = window.location.search.substring(1);
		let json= JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
		let result = {};
		Object.keys(json).forEach(key => {
			result[decodeURIComponent(key)] = decodeURIComponent(json[key]);
		})
		return result;
	} catch (e){
		return {};
	}
}

export function handleClickOutside({ target }){
	Object.keys(this.refList).forEach(key => {
		let ref = this.refList[key];
		if (ref.current && !ref.current.contains(target)) {
			this.setState({ [key]: false })
		}
	})
}

export function betCondition(transaction){
	if(transaction.bet_condition === 'up'){
		return 'Tăng';
	}
	if(transaction.bet_condition === 'down'){
		return 'Giảm';
	}
}

export async function getSocket(){
	if (window.socket) {
		return window.socket;
	}
	return new Promise(resolve => {
		let intervalCheckSocket = setInterval(() => {
			if (window.socket) {
				clearInterval(intervalCheckSocket);
				return resolve(window.socket);
			}
		}, 200);
	})
}

export function isDesktop(){
	return window.innerWidth > 1024;
}

export function isMobile(){
	return !isDesktop();
}

export function preventDefault(e){
	e && e.preventDefault();
}
