import { takeLatest, put} from 'redux-saga/effects';

import {
	fetchingConfig,
	fetchingConfigSuccess,

	updateAppConfigAction
} from '../actions';
import api from "../../lib/api";

export function* watchFetchingConfig() {
    yield takeLatest(fetchingConfig, fetchingConfigSaga)
}

function* fetchingConfigSaga() {
    try {
        let { data, success } = yield api.getConfig();
        if (success) {
            yield put(fetchingConfigSuccess(data))
        }
    } catch (error) {}
}

export function* watchFetchingConfigSuccess() {
	yield takeLatest(fetchingConfigSuccess, fetchingConfigSuccessSaga);
}

function* fetchingConfigSuccessSaga({payload}) {
	yield put(updateAppConfigAction(payload));
}
