import { takeLatest, put} from 'redux-saga/effects';
import api from '../../lib/api';

import {
	fetchingUser,
	fetchingUserFail,
	fetchingUserSuccess,

	logoutUser,

	updateMemberAction,
	clearMemberAction,

	updateAppConfigAction
} from '../actions';
import storage from "../../lib/storage";

export function* watchFetchingUser() {
    yield takeLatest(fetchingUser, fetchingUserSaga)
}

function* fetchingUserSaga() {
    try {
        let { data, success } = yield api.getProfile();
        if (success) {
            yield put(fetchingUserSuccess(data))
        } else {
            yield put(fetchingUserFail())
        }
    } catch (error) {
		yield put(fetchingUserFail())
    }
}

export function* watchFetchingUserSuccess() {
	yield takeLatest(fetchingUserSuccess, fetchingUserSuccessSaga);
}

export function* watchFetchingUserFail() {
	yield takeLatest(fetchingUserFail, fetchingUserSuccessSaga);
}

function* fetchingUserSuccessSaga({payload}) {
	yield put(updateMemberAction(payload));
	yield storage.saveUser(payload);
	yield put(updateAppConfigAction({ app_loading: false }));
}

export function* watchLogoutUser() {
	yield takeLatest(logoutUser, logoutUserSaga)
}

function* logoutUserSaga() {
	try {
		let { success } = yield api.ax.post('logout');
		if(success){
			yield put(clearMemberAction());
			yield storage.saveUser(null);
		}
	} catch (error) {
		console.log('Log out error', error.message);
	}
}
