import {Component} from "react";
import {connect} from "react-redux";

import api from "../../../lib/api";
import {Tree, TreeNode} from "react-organizational-chart";


function StyledNode({ children }) {
	return (
		<div style={{padding: '5px', borderRadius: '8px', display: 'inline-block', border: '1px solid green', color: 'red'}}>
			{children}
		</div>
	);
}

function NodeValue({ id, image, name, parent, title }) {
	return (
		<div style={{padding: '6px', borderRadius: '6px', display: 'inline-block', border: '1px solid green'}}>
			<div><img src={image} alt={name}/></div>
			<div style={{ marginTop: '5px', color: '#ff0023', fontWeight: 'bold' }}>{name}</div>
			<div style={{ marginTop: '3px', color: 'white' }} dangerouslySetInnerHTML={{ __html: `(${title})` }} />
		</div>
	);
}

class Network extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: []
		}
	}

	componentDidMount() {
		api.getNetwork().then(({ data, success }) => {
			if(success){
				this.setState({ data: data });
			}
		});
	}

	loopChild = (data) => {
		return data.map((item) => {
			if (item.children && item.children.length) {
				return <TreeNode key={item.id} label={<NodeValue {...item} />} >{this.loopChild(item.children)}</TreeNode>;
			}
			return (
				<TreeNode key={item.id} label={<NodeValue {...item} />} />
			);
		});
	}

	render() {
		let { data } = this.state;
		if(!data.id){
			return null;
		}
		return (<Tree
			label={<NodeValue {...data} />}
			lineBorderRadius="5px"
			lineColor="green"
			lineHeight="20px"
			lineWidth="2px"
			nodePadding="5px">
			{data.children && data.children.length && this.loopChild(data.children)}
		</Tree>)
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, null)(Network);
