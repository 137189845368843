import {Component} from "react";
import {connect} from "react-redux";
import {updateAppConfigAction} from "../../../redux/actions";

class PopupCongratulation extends Component{
	onCloseModal = () => {
		this.props.updateAppConfigAction({ win_value: 0 });
	}

	render() {
		let { win_value } = this.props.appReducer;
		let { current_point_type } = this.props.memberReducer;
		return <div>
			{win_value > 0 && <div data-v-d291ee96="" data-v-5b2de64c="" className="popupCongratulation">
				<div data-v-d291ee96="" className="mask" />
				<div data-v-d291ee96="" className="popupCongratulationContent">
					<div data-v-d291ee96="" className="winLive  d-flex justify-content-center">
						<div data-v-d291ee96="" className="wrapNotify congratulation h-100 ">
							<div data-v-d291ee96="" className="boxNotify">
								<img data-v-d291ee96="" src="images/icon_win.svg"
									 alt="" className="iconWin" />
								<div data-v-d291ee96="" className="badgeDemo">{current_point_type === 'demo' ? 'Demo' : 'Thực'}</div>
								<div data-v-d291ee96=""
									 className="boxContent d-flex justify-content-center align-items-center flex-column mt-3">
									<div data-v-d291ee96="" className="d-flex align-items-center"/>
									<p data-v-d291ee96="" className="titleWin">Xin chúc mừng!</p> <span
									data-v-d291ee96=""
									className="message font-40 font-weight-700">+${win_value}</span>
								</div>
								<span data-v-d291ee96="" onClick={this.onCloseModal} className="pointer">
												<svg data-v-d291ee96=""
													 xmlns="http://www.w3.org/2000/svg"
													 width="13.21"
													 height="13.21"
													 viewBox="0 0 13.21 13.21"
													 className="iconClose">
													<path
														data-v-d291ee96="" id="Path_2957" data-name="Path 2957"
														d="M13.823,1.387a1.321,1.321,0,0,0-1.868,0L7.6,5.737l-4.35-4.35A1.321,1.321,0,1,0,1.387,3.255L5.737,7.6l-4.35,4.35a1.321,1.321,0,1,0,1.868,1.868L7.6,9.473l4.35,4.35a1.321,1.321,0,1,0,1.868-1.868L9.473,7.6l4.35-4.35A1.321,1.321,0,0,0,13.823,1.387Z"
														transform="translate(-1 -1)" fill="#fff"/></svg>
											</span>
							</div>
						</div>
					</div>
				</div>
			</div>}
		</div>
	}
}


function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	updateAppConfigAction
})(PopupCongratulation);
