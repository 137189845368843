import '../../assets/css/dashboard.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { Component } from 'react';
import DataTable  from 'react-data-table-component';

import {formatNumber, setClassNameCondition, showNotification} from "../../lib/helper";
import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../redux/actions";
import {withRouter} from "react-router-dom";
import api from "../../lib/api";
import constants from "../../lib/constants";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DateRangePicker from "../../lib/custom/DateRangePicker";
import moment from "moment";

const highChartOptions = {
	chart: {
		type: "pie",
		height: 200,
		width: 200,
		backgroundColor: "transparent"
	},
	title: {
		text: '<p class="name text-center mb-0" style="font-size: 14px; color: #fff;">Lượt giao dịch</p><p class="value text-center mb-0" style="font-size: 20px; color: #fff; font-weight: bold;">' + 0 + "</p>",
		align: "center",
		verticalAlign: "middle",
		style: {
			color: "#fff"
		},
		useHTML: !0,
		floating: !0,
		y: 30,
		x: 0
	},
	plotOptions: {
		pie: {
			innerSize: "80%",
			dataLabels: {
				enabled: !1
			},
			borderWidth: 0
		},
		series: {
			dataLabels: [{
				enabled: !0
			}]
		}
	},
	tooltip: {
		formatter: function() {
			return `${this.point.name} <b>${this.point.y}</b> vòng`;
		}
	},
	series: {
		type: "pie",
		data: [],
		dataLabels: [{
			enabled: false
		}]
	},
	credits: {
		enabled: !1
	},
};

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_show_withdrawal_box: false,
			page: 1,
			per_page: 10,
			transaction_list: [],
			transaction_total: 0,
			sort_selector: 'created_at',
			sort_direction: 'desc',
			filter_from: moment().subtract(1, 'months'),
			filter_to: moment(),

			total_all: 0,
			percent_up: -1,
			fund: 0,
			total_win: 0,
			total_lose: 0,
			total_draw: 0,
			revenue: 0,
			profit: 0,
			options: highChartOptions,
		};
		this.columns = [
			{
				name: 'Mã giao dịch',
				selector: 'code',
				sortable: false,
			},
			{
				name: 'Thời gian',
				selector: 'created_at',
				sortable: true,
				format: (row, index) => (moment(row.created_at).format('HH:mm DD/MM')),
			},
			{
				name: 'Đã chọn',
				selector: 'bet_condition',
				sortable: true,
				format: (row) => (<span className={'bet_condition '+row.bet_condition}>{row.bet_condition === 'up' ? 'Tăng' : 'Giảm'}</span>)
			},
			{
				name: 'Giá mở ($)',
				selector: 'open_price',
				sortable: true,
				allowOverflow: true,
				right: true,
				format: (row, index) => (row.open_price ? <span>{formatNumber(row.open_price)}</span> : '-'),
			},
			{
				name: 'Giá đóng ($)',
				selector: 'close_price',
				sortable: true,
				allowOverflow: true,
				right: true,
				format: (row, index) => (row.close_price ? <span>{formatNumber(row.close_price)}</span> : '-'),
			},
			{
				name: 'Giá đặt ($)',
				selector: 'bet_value',
				sortable: true,
				right: true,
				format: (row, index) => (<span>{formatNumber(row.bet_value)}</span>),
			},
			{
				name: 'Lợi nhuận ($)',
				selector: 'value',
				sortable: true,
				right: true,
				format: (row, index) => (<span style={{ color: row.value < 0 ? 'red' : 'green' }}>{formatNumber(row.value)}</span>),
			},
		];
	}

	componentDidMount() {
		api.getDashboard()
			.then(({ data, success, message }) => {
				let pieData = [
					{
						y: data.total_win,
						color: 'rgb(45, 85, 253)',
						name: 'Thắng'
					},
					{
						y: data.total_draw,
						color: 'rgb(123, 115, 142)',
						name: 'Hòa'
					},
					{
						y: data.total_lose,
						color: 'rgb(255, 42, 85)',
						name: 'Thua'
					}
				];
				let options = {
					...highChartOptions,
					series: {
						...highChartOptions.series,
						data: pieData
					},
					title: {
						...highChartOptions.title,
						text: '<p class="name text-center mb-0" style="font-size: 14px; color: #fff;">Lượt giao dịch</p><p class="value text-center mb-0" style="font-size: 20px; color: #fff; font-weight: bold;">' + data.total_all + "</p>",
					}
				};
				success && this.setState({ ...data, options });
			});
		this.loadTransaction();
	}

	loadTransaction = () => {
		let { page, per_page, sort_selector, sort_direction, filter_from, filter_to } = this.state;
		api.getTransaction({ limit: per_page, page, transaction_type: constants.TRANSACTION_TYPE.BET, sort_selector, sort_direction,
			filter_from: filter_from.format('YYYY-MM-DD'),
			filter_to: filter_to.format('YYYY-MM-DD'),
			point_type: 'real'
		}).then(({ data, success }) => {
				if(success){
					this.setState({ transaction_list: data.data, transaction_total: data.total });
				}
			})
	}

	handlePageChange = (page) => {
		this.setState({ page }, this.loadTransaction)
	}

	handlePerRowsChange = (per_page) => {
		this.setState({ per_page }, this.loadTransaction)
	}

	onSort = (column, sortDirection) => {
		this.setState({ sort_selector: column.selector, sort_direction: sortDirection }, this.loadTransaction)
	}

	handleChangeDateRange = (start, end) => {
		this.setState({ filter_from: start, filter_to: end }, this.loadTransaction)
	}

	render() {
		let { total_all, total_win, revenue, profit, fund, options, percent_up, transaction_total, transaction_list, filter_from, filter_to } = this.state;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-7a0c51c4="" data-v-b0e45e8c="" className="tradeHistory">
						<div data-v-7a0c51c4="" className="container">
							<div data-v-7a0c51c4=""
								 className="headerContent justify-content-between align-items-center mb-4">
								<div data-v-7a0c51c4="" className="d-flex justify-content-between"><h1
									data-v-7a0c51c4=""
									className="font-30 color-white font-weight-700 text-capitalize position-relative">
									Số liệu B.O
								</h1> <a data-v-7a0c51c4="" href="#"
										 className="d-inline-flex align-items-center color-white"><span
									data-v-7a0c51c4="" className="btn-eyes"/> <span
									data-v-7a0c51c4="">Ẩn số dư</span></a></div>
								<div data-v-7a0c51c4="" className="row">
									<div data-v-7a0c51c4="" className="col-md-12 col-lg-6 mb-3">
										<div data-v-7a0c51c4="" className="bostats">
											<div data-v-b6d115ca="" data-v-7a0c51c4="" className="wrap-chart">
												<div data-v-b6d115ca="" className="nodata">
													<div data-v-b6d115ca="" className="wrap-chart">
														<div data-v-b6d115ca="" className="chart-instance">
															<div data-v-b6d115ca="" className="row">
																<div data-v-b6d115ca="" className="col-md-6 mb-3">
																	<div data-v-b6d115ca="" className="d-flex justify-content-center align-items-center">
																		<HighchartsReact
																			ref={ref => this.refChart = ref}
																			highcharts={Highcharts}
																			options={options}
																		/>
																	</div>
																</div>
																<div data-v-b6d115ca=""
																	 className="col-md-6 py-md-5 flex align-middle justify-content-around">
																	<div data-v-b6d115ca="" className="row wrap">
																		<div data-v-b6d115ca=""
																			 className="label colorWhite mb-3 col-md-12">
																			<span data-v-b6d115ca="" className="round"
																				  style={{ backgroundColor: 'rgb(45, 85, 253)' }} />
																			<span data-v-b6d115ca="">Tổng vòng thắng</span>
																		</div>
																		<div data-v-b6d115ca=""
																			 className="label colorWhite mb-3 col-md-12">
																			<span data-v-b6d115ca="" className="round"
																				  style={{backgroundColor: 'rgb(123, 115, 142)'}} />
																			<span
																				data-v-b6d115ca="">Tổng vòng hòa</span>
																		</div>
																		<div data-v-b6d115ca=""
																			 className="label colorWhite mb-3 col-md-12">
																			<span data-v-b6d115ca="" className="round"
																				  style={{backgroundColor: 'rgb(255, 42, 85)'}} />
																			<span
																				data-v-b6d115ca="">Tổng vòng thua</span>
																		</div>
																	</div>
																</div>
																<div data-v-b6d115ca=""
																	 className="col-md-6 lg-py-3 borderRightColor mb-sm-3">
																	<p data-v-b6d115ca="" className="colorWhite67 text-center m-0 mb-2 name">Tỷ lệ thắng</p>
																	<p data-v-b6d115ca="" className="colorWhite text-center value">{total_all > 0 ? Math.ceil(total_win*100/total_all) : 0}%</p>
																</div>
																<div data-v-b6d115ca="" className="col-md-6"><p
																	data-v-b6d115ca=""
																	className="colorWhite67 text-center m-0 mb-2 name">Tổng giao dịch</p>
																	<p data-v-b6d115ca="" className="colorWhite text-center value">${formatNumber(fund)}</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-v-7a0c51c4="" className="col-md-12 col-lg-6 mb-3">
										<div data-v-7a0c51c4="" className="row">
											<div data-v-7a0c51c4="" className="col-md-12 col-lg-6 mb-3">
												<div data-v-7a0c51c4=""
													 className="userStat profits py-5 d-flex align-middle justify-content-around">
													<div data-v-7a0c51c4="" className="wrap">
														<div data-v-7a0c51c4="" className="icon text-center">
															<svg data-v-7a0c51c4="" xmlns="http://www.w3.org/2000/svg"
																 width="43.525" height="40.623"
																 viewBox="0 0 43.525 40.623">
																<g data-v-7a0c51c4="" id="bar-graph-profit"
																   transform="translate(0 -1)">
																	<path data-v-7a0c51c4="" id="Path_29851"
																		  data-name="Path 29851"
																		  d="M42.074,38.722H40.623V2.451A1.451,1.451,0,0,0,39.173,1h-5.8a1.451,1.451,0,0,0-1.451,1.451V38.722h-5.8V14.058a1.451,1.451,0,0,0-1.451-1.451h-5.8a1.451,1.451,0,0,0-1.451,1.451V38.722h-5.8V25.664a1.451,1.451,0,0,0-1.451-1.451h-5.8A1.451,1.451,0,0,0,2.9,25.664V38.722H1.451a1.451,1.451,0,0,0,0,2.9H42.074a1.451,1.451,0,1,0,0-2.9Z"
																		  fill="#b9c7ff"/>
																</g>
															</svg>
														</div>
														<div data-v-7a0c51c4="" className="info">
															<p data-v-7a0c51c4="" className="name m-0 mb-2">Lợi nhuận ròng</p>
															<p data-v-7a0c51c4="" className="value colorWhite m-0 d-flex">
																<span data-v-7a0c51c4="" className="font-arial">$</span>
																<span data-v-7a0c51c4="">{formatNumber(profit)}</span>
															</p>
														</div>
													</div>
												</div>
											</div>
											<div data-v-7a0c51c4="" className="col-md-12 col-lg-6 mb-3">
												<div data-v-7a0c51c4=""
													 className="userStat revenue py-5 d-flex align-middle justify-content-around">
													<div data-v-7a0c51c4="" className="wrap">
														<div data-v-7a0c51c4="" className="icon text-center">
															<svg data-v-7a0c51c4="" xmlns="http://www.w3.org/2000/svg"
																 width="49.009" height="55.129"
																 viewBox="0 0 49.009 55.129">
																<g data-v-7a0c51c4="" id="savings"
																   transform="translate(-4.441 -1)">
																	<path data-v-7a0c51c4="" id="Path_29852"
																		  data-name="Path 29852"
																		  d="M30.559,1A11.559,11.559,0,1,0,42.119,12.559,11.559,11.559,0,0,0,30.559,1Zm5.075,12.188-4.446,4.446a.889.889,0,0,1-1.257,0l-4.446-4.446a.889.889,0,0,1,0-1.257l4.446-4.446a.889.889,0,0,1,1.257,0l4.446,4.446A.889.889,0,0,1,35.634,13.188Z"
																		  transform="translate(-1.613)"
																		  fill="#bbe8e0"/>
																	<path data-v-7a0c51c4="" id="Path_29853"
																		  data-name="Path 29853"
																		  d="M19.367,49l1.459,11.67a.889.889,0,0,0,.882.779H39.492a.889.889,0,0,0,.882-.779L41.833,49Z"
																		  transform="translate(-1.654 -5.32)"
																		  fill="#bbe8e0"/>
																	<path data-v-7a0c51c4="" id="Path_29854"
																		  data-name="Path 29854"
																		  d="M53.149,32.773a15.086,15.086,0,0,0-23.314,4.239V28.95c-.3.019-.589.044-.889.044s-.594-.026-.889-.044v8.062A15.086,15.086,0,0,0,4.742,32.773a.889.889,0,0,0,0,1.334,15.056,15.056,0,0,0,19.339.513,13.3,13.3,0,0,1,3.93,8.6H18.276a.889.889,0,0,0-.882,1l.1.779H40.4l.1-.779a.889.889,0,0,0-.882-1H29.88a13.3,13.3,0,0,1,3.93-8.6,15.056,15.056,0,0,0,19.339-.513.889.889,0,0,0,0-1.334Z"
																		  transform="translate(0 -3.098)"
																		  fill="#bbe8e0"/>
																</g>
															</svg>
														</div>
														<div data-v-7a0c51c4="" className="info">
															<p data-v-7a0c51c4="" className="name m-0 mb-2"> Tổng doanh thu</p>
															<p data-v-7a0c51c4="" className="value colorWhite m-0 d-flex">
																<span data-v-7a0c51c4="" className="font-arial">$</span>
																<span data-v-7a0c51c4="">{formatNumber(revenue)}</span>
															</p>
														</div>
													</div>
												</div>
											</div>
											<div data-v-7a0c51c4="" className="col-md-12">
												<div data-v-417299ea="" data-v-7a0c51c4="" className="wrap-chart">
													<div data-v-417299ea="" className="chart-instance py-3">
														<div data-v-417299ea="" className="row">
															<div data-v-417299ea="" className="col-md-12 mb-2"><h4
																data-v-417299ea=""
																className="text-center font-20 color-white-50 mb-3">Tóm tắt giao dịch</h4>
																<div data-v-417299ea="" className={setClassNameCondition(percent_up > -1, '','nodata','customChartPercent')}>
																	<div data-v-417299ea="" className="percentUp" style={{width: percent_up > -1 ? `${percent_up}%` : '0%'}}/>
																</div>
															</div>
															<div data-v-417299ea="" className="col-sm-6 col-md-6 pl-4">
																<p data-v-417299ea=""><span data-v-417299ea=""
																							className="name colorWhite">Giảm</span>
																	<span data-v-417299ea=""
																		  className="value colorRed">{(100 - percent_up).toFixed(2)}%</span></p>
															</div>
															<div data-v-417299ea=""
																 className="col-sm-6 col-md-6 pr-4 text-right"><p
																data-v-417299ea=""><span data-v-417299ea=""
																						 className="value colorGreen">{percent_up}%</span>
																<span data-v-417299ea=""
																	  className="name colorWhite">Tăng</span></p></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div data-v-7a0c51c4=""
								 className="headerContent d-flex flex-lg-row flex-column justify-content-between align-items-center mb-3">
								<h1 data-v-7a0c51c4="" className="font-30 color-white font-weight-700 text-capitalize">Lịch sử Giao dịch</h1>
								<div data-v-7a0c51c4="" className="d-lg-flex align-items-end">
									<DateRangePicker handleChangeDateRange={this.handleChangeDateRange} initStart={filter_from} initEnd={filter_to} />
								</div>
							</div>
							<div data-v-7a0c51c4="" className="table-responsive"  style={{ borderRadius: '3px' }}>
								<DataTable
									columns={this.columns}
									data={transaction_list}
									pagination
									paginationServer
									paginationTotalRows={transaction_total}
									onChangeRowsPerPage={this.handlePerRowsChange}
									onChangePage={this.handlePageChange}
									sortServer
									onSort={this.onSort}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Dashboard));
