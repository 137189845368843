import {setItem, KEY} from "./database";

class Storage {
	#_user = null;
	#_config = null;
	#_payload = null;

	setUser(user) {
		if(typeof user === 'string'){
			user = JSON.parse(user);
		}
		this.#_user = user;
		return this;
	}

	saveUser(user){
		user = this.setUser(user).getUser();
		setItem(KEY.USER_INFORMATION, user);
		return this;
	}

	setConfig(config){
		if(typeof config === 'string'){
			config = JSON.parse(config);
		}
		this.#_config = config;
		return this;
	}

	saveConfig(config){
		config = this.setConfig(config).getConfig();
		setItem(KEY.APP_CONFIG, config);
		return this;
	}

	getAccessToken() {
		return this.#_user?.access_token || null;
	}

	getUser(){
		return this.#_user;
	}

	getConfig() {
		return this.#_config;
	}

	getPayload(){
		let data = this.#_payload;
		this.#_payload = null;
		return data;
	}
}

export default new Storage();
