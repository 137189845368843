import '../../assets/css/profile.css';

import { Component } from 'react';
import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../redux/actions";
import {withRouter} from "react-router-dom";
import QRCode from 'qrcode';
import api from "../../lib/api";

import {
	formatNumber, getUrl, setClassNameCondition,
	showNotification
} from "../../lib/helper";

const PAYMENT_METHODS = {
	usdt: {
		name: 'usdt',
		rate: 'usdt_per_usd',
		currency: 'USDT'
	},
};

class Deposit extends Component{
	constructor(props) {
		super(props);
		this.state = {
			...props.memberReducer,
			deposit_value: 0,
			deposit_value_usd: 0,
			is_show_deposit_success: false
		};
	}

	onChangeDepositValue = (e) => {
		let value = parseFloat(e.target.value.toString().replace(/,/g,''));
		let rate = this.props.appReducer.usdt_per_usd;
		this.setState({ deposit_value_usd: Math.floor(value / rate), deposit_value: value });
	}

	onChangeUSD = (e) => {
		let rate = this.props.appReducer.usdt_per_usd;
		let value = parseFloat(e.target.value.toString().replace(/,/g,''));
		this.setState({ deposit_value_usd: value, deposit_value: value * rate });
	}

	onRecharge = () => {
		let { deposit_value_usd } = this.state;
		let { usdt_address } = this.props.appReducer;
		api.postRecharge({ amount: deposit_value_usd })
			.then(({ data, message, success }) => {
				showNotification({ message, type: success ? 'success' : 'danger' });
				success && this.setState({ is_show_deposit_success: true, deposit_transaction: data.code });
				QRCode.toString(usdt_address).then(qrCode => {
					this.setState({ qr_code: qrCode });
				});
			});
	}

	onChangePaymentMethod = (method) => () => {
		let { deposit_value_usd } = this.state;
		let rate = this.props.appReducer.usdt_per_usd;
		this.setState({ payment_method: method, deposit_value: deposit_value_usd * rate })
	}

	onCopyAddress = () => {
		let { usdt_address } = this.props.appReducer;
		navigator.clipboard.writeText(usdt_address)
			.then(() => {
				showNotification({ type: 'success', message: 'Đã sao chép vào bộ nhớ' });
			})
			.catch(e => {
				showNotification({ type: 'danger', message: e.message });
			})
	}

	render() {
		let { deposit_value, deposit_value_usd, is_show_deposit_success, deposit_transaction, payment_method, qr_code } = this.state;
		let { minimum_deposit, usdt_address } = this.props.appReducer;
 		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-9792f17c="" data-v-b0e45e8c="">
						<div data-v-9792f17c="" className="profileContent">
							<div data-v-9792f17c="" className="container">
								<div data-v-9792f17c="" className="panel panel--security">
									<div data-v-9792f17c="" className="panel-header">
										<h3 data-v-9792f17c="" className="panel-header-title">Nạp tiền</h3>
										<span>Mạng lưới : Binance Smart Chain ( BEP20 )</span>
									</div>
									<div data-v-9792f17c="" className="panel-body">
										<div data-v-9792f17c="" className="row mb-5">
											<div data-v-9792f17c="" className="col-md-3 col-xs-12">
												<div data-v-9792f17c="" className="form-group">
													<span data-v-9792f17c="" className="labelInput">Số tiền muốn nạp (USDT)</span>
													<input data-v-9792f17c=""
														   placeholder="Nhập số tiền muốn nạp"
														   value={formatNumber(deposit_value, true)}
														   onChange={this.onChangeDepositValue}
														   type="text" className="form-control mb-2 winput input-deposit"/>
												</div>
											</div>
											<div data-v-9792f17c="" className="col-md-3 col-xs-12">
												<div data-v-9792f17c="" className="form-group deposit-usd">
													<span data-v-9792f17c="" className="labelInput">Số tiền muốn nhận (USD)</span>
													<span className='usd-symbol'>$</span>
													<input
														placeholder="Nhập số tiền muốn nhận (USD)"
														data-v-9792f17c=""
														value={formatNumber(deposit_value_usd, true)}
														onChange={this.onChangeUSD}
														type="text"
														className="form-control mb-2 winput input-deposit"/>
												</div>
											</div>
											<div data-v-9792f17c="" className="col-md-3 col-xs-12">
												<button data-v-9792f17c="" style={{ marginTop: '25px', height: '43px', width: '100%' }}
														type="button"
														onClick={this.onRecharge}
														className="button wbtn btn-radius btn-large btn-border color-white btn-secondary">Yêu Cầu Nạp
												</button>
											</div>
										</div>
										<div data-v-9792f17c="" className="row">
											<div data-v-9792f17c="" className="col-12">
												<p data-v-110c803e="" className="noticeDeposit">
													<span data-v-110c803e="">Giá trị nhận tối thiểu là: ${formatNumber(minimum_deposit)} ({formatNumber(minimum_deposit * this.props.appReducer.usdt_per_usd)} USDT)</span>
													<br/>
													<span data-v-110c803e="">( Tỉ giá quy đổi hiện tại: $1 = {formatNumber(this.props.appReducer.usdt_per_usd)} USDT )</span>
												</p>
											</div>
										</div>
										{is_show_deposit_success && <div className='row mb-5'>
												<div  data-v-9792f17c="" className="col-12">
													<div data-v-110c803e="" className="noticeDeposit" style={{ color: '#31BAA0', marginBottom: '7px', fontSize: '18px' , fontWeight: 'bolder' }}>Gửi yêu cầu thành công</div>
													<div data-v-110c803e="" className="noticeDeposit"><span>Vui lòng chuyển khoản đúng <b>{formatNumber(deposit_value)} USDT</b> vào địa chỉ ví sau!</span></div>
													<div data-v-110c803e="" className="noticeDeposit deposit">
														<span>USDT address:</span>
														<div className='copy-usdt'>
															<input type="text" disabled={true}  className="form-control mb-2 winput input-deposit" value={usdt_address} />
															<span onClick={this.onCopyAddress} className='pe pe-7s-copy-file' />
														</div>
													</div>
													<div data-v-110c803e="" >
														<div style={{ maxHeight: '200px', maxWidth: '200px' }} dangerouslySetInnerHTML={{__html: qr_code}} />
													</div>
												</div>
										</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Deposit));

