import { createReducer } from 'redux-act';
import { updateMemberAction, clearMemberAction } from '../actions';

const defaultState = {
	first_name: '',
	last_name: '',
	email:  null,
	current_point_type: 'demo'
};

export default createReducer({
	[updateMemberAction]: (state, payload) => {
		return {...state, ...payload};
	},
	[clearMemberAction]: (state) => {
		return defaultState;
	}
}, defaultState);
