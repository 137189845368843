import { Component } from 'react';
import DateRangePickerLib from 'react-bootstrap-daterangepicker';
import moment from "moment";

export default class DateRangePicker extends Component{
	constructor(props) {
		super(props);
	}

	render() {
		let { formatLocale, initStart, initEnd, handleChangeDateRange } = this.props;
		return <DateRangePickerLib
			initialSettings={{ startDate: initStart || moment(), endDate: initEnd || moment(), locale: {
				format: formatLocale || 'DD/MM/YYYY',
				"separator": " - ",
				"applyLabel": "Áp dụng",
				"cancelLabel": "Hủy",
				"fromLabel": "Từ",
				"toLabel": "Đến",
				"customRangeLabel": "Custom",
				"daysOfWeek": [
					"CN",
					"T2",
					"T3",
					"T4",
					"T5",
					"T6",
					"T7"
				],
				"monthNames": [
					"Tháng Một",
					"Tháng Hai",
					"Tháng Ba",
					"Tháng Tư",
					"Tháng Năm",
					"Tháng Sáu",
					"Tháng Bảy",
					"Tháng Tám",
					"Tháng Chín",
					"Tháng Mười",
					"Tháng M.Một",
					"Tháng M.Hai"
				],
				"firstDay": 1
			} }}
			onCallback={handleChangeDateRange}>
			<input type="text" className="form-control dateRageInput" />
		</DateRangePickerLib>
	}
}
