import { all, fork } from 'redux-saga/effects';
import * as userSaga from './userSaga';
import * as configSaga from './configSaga';
import * as transactionSaga from './transactionSaga';

export default function* rootSaga() {
  yield all([
    ...Object.values(userSaga),
    ...Object.values(configSaga),
    ...Object.values(transactionSaga),
  ].map(fork))
}
