import { Component } from 'react'
import Info from "./detail/info";
import Board from "./detail/board";
import {formatNumber, setClassNameCondition} from "../../../lib/helper";
import constants from "../../../lib/constants";
import Countdown from "./countdown";

class DetailModal extends Component{
	constructor(props) {
		super(props);
		this.state = {
			selected_tab: 'board',
		};
	}

	onChangeTab = (tab) => () => {
		this.setState({ selected_tab: tab });
	}

	render() {
		let { onClose, detail } = this.props;
		let { selected_tab } = this.state;
		let { type, schedule, value, total_reward, end, status } = (detail || {});
		return (
			<div data-v-2f4ce6a2="" role="document" className="modal-dialog">
				<div data-v-2f4ce6a2="" className="modal-content"
					 style={{ backgroundImage: "url('images/bg_challenge_2.png')" }}>
					<div data-v-2f4ce6a2="" className="modal-header">
						<div data-v-2f4ce6a2="" className="container">
							<div data-v-2f4ce6a2="" className={setClassNameCondition(status ==='live', 'mb-3', '', 'd-flex w-100 align-items-center')}>
								<button data-v-2f4ce6a2="" onClick={onClose} type="button" aria-label="Close" className="btn btn-back mr-2">
									<svg data-v-2f4ce6a2=""
										 xmlns="http://www.w3.org/2000/svg"
										 width="16.003" height="12.059"
										 viewBox="0 0 16.003 12.059">
										<g data-v-2f4ce6a2=""
										   id="rounded-head-arrow"
										   transform="translate(16.003 12.059) rotate(180)">
											<path data-v-2f4ce6a2=""
												  id="Path_29622"
												  data-name="Path 29622"
												  d="M15.5,4.945l-5-4.5a1.5,1.5,0,1,0-2,2.229l2.09,1.885H1.5a1.5,1.5,0,1,0,0,3h9.092L8.5,9.444a1.5,1.5,0,1,0,2.006,2.23l5-4.5a1.5,1.5,0,0,0,0-2.23Z"
												  fill="#fff" />
										</g>
									</svg> Trở lại
								</button>
								<label data-v-2f4ce6a2="" className="titleHeader mb-0 text-capitalize">Top {type === constants.CHALLENGE_TYPE.TRADING ? 'G.D' : 'VIP'} { schedule === constants.CHALLENGE_SCHEDULE.MONTH ? 'Tháng' : 'Tuần' } {value}</label>
							</div>
							{status === 'live' && <p data-v-2f4ce6a2="" className="timeLeftText">
								<span data-v-2f4ce6a2="">Thời gian còn lại</span>
							</p>}
							<div data-v-2f4ce6a2="" className={setClassNameCondition(status==='live', '', 'mt68', 'd-flex align-items-center')}>
								<div data-v-2f4ce6a2="" className="countDown text-center position-relative">
									{status === 'live'
										? <Countdown  countdownEnd={end} />
										: <div data-v-27e0fb74="" className="ended p-3">
											<p data-v-27e0fb74="" className="m-0 font-18 text-center">Giải đấu đã kết thúc</p>
										</div>}
								</div>
								<div data-v-2f4ce6a2=""
									 className="colPrizePool text-center"><p
									data-v-2f4ce6a2="">${formatNumber(total_reward)}</p> <span
									data-v-2f4ce6a2="">Tổng phần thưởng</span>
								</div>
								<div data-v-2f4ce6a2=""
									 className="colChallengeType ml-auto text-center">
									<p data-v-2f4ce6a2=""
									   className="text-capitalize">{type === constants.CHALLENGE_TYPE.TRADING ? 'KLGD' : 'Hoa hồng'}</p><span
									data-v-2f4ce6a2="">Loại giải đấu</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div data-v-2f4ce6a2="" className="mainContent">
					<div data-v-2f4ce6a2="" className="container">
						<ul data-v-2f4ce6a2="" id="pills-tab" role="tablist"
							className="nav nav-pills menuHeaderLink">
							<li data-v-2f4ce6a2="" className="nav-item">
								<a data-v-2f4ce6a2="" onClick={this.onChangeTab('info')} className={setClassNameCondition(selected_tab === 'info', 'active', '', 'nav-link text-capitalize')}>Thông tin</a>
							</li>
							<li data-v-2f4ce6a2="" className="nav-item">
								<a data-v-2f4ce6a2="" onClick={this.onChangeTab('board')} className={setClassNameCondition(selected_tab === 'board', 'active', '', 'nav-link text-capitalize')}>Bảng xếp hạng</a>
							</li>
						</ul>
						{ selected_tab === 'info' ? <Info detail={detail} /> : <Board detail={detail} /> }
					</div>
				</div>
				<div data-v-2f4ce6a2="" className="rowFooter">
					<div data-v-2f4ce6a2="" className="container" />
				</div>
			</div>
		)
	}
}

export default DetailModal;
