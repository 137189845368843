import { Component } from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import solidGauge from "highcharts/modules/solid-gauge.js";

import {
	setClassNameCondition,
	getDeviceInfo, getSocket
} from "../../../lib/helper";
import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../../redux/actions";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {getIndicatorDefault} from "../../../lib/trade_default_data";
import api from '../../../lib/api';

solidGauge(Highcharts);

const TABS = {
	indicator: 'indicator',
	last_result: 'last_result',
};

const { deviceVersion } = getDeviceInfo();
const resultLength = deviceVersion === 'pc' ? 100 : 60;

class AnalysisWrapper extends Component{
	constructor(props) {
		super(props);
		this.state = {
			tab_selected: TABS.indicator,
			trading_data: [],
		};
		this.indicatorCharts = {};
		this.meta_os = getIndicatorDefault();
		this.meta_su = getIndicatorDefault({
			'chart.height': 70,
			'pane.size': 176,
			'series.name': 'Summary',
			'responsive.rules': [{
				condition: {
					maxWidth: 89
				},
				chartOptions: {
					chart: {
						height: 44
					},
					pane: [{
						size: 88
					}]
				}
			}]
		});
		this.meta_ma = getIndicatorDefault();
	}

	onChangeTab = (tab) => () => {
		this.setState({ tab_selected: tab });
	}

	getTradingData = () => {
		api.getTradingData().then((data) => {
			this.setState({ trading_data: data });
		});
	}

	componentDidMount() {
		this.getTradingData();
		getSocket().then(socket => {
			socket.on('WE_PRICE', (price) => {
				let { trading_data } = this.state;
				if(trading_data.length && price.second === 1){
					let new_data = _.takeRight([...trading_data, price], resultLength);
					setTimeout(() => {
						this.setState({ trading_data: new_data })
					}, 1000);
				}
			});
			socket.on('WE_INDICATOR', (data) => {
				Object.keys(data).forEach(key => {
					if(this.indicatorCharts[key] && this.indicatorCharts[key].chart){
						this.indicatorCharts[key].chart.series[0].points[0].update(data[key]);
					}
				});
			});
		});
	}

	componentWillUnmount() {
		if(window.socket){
			window.socket.removeAllListeners("WE_PRICE");
			window.socket.removeAllListeners("WE_INDICATOR");
		}
	}

	render() {
		let {trading_data} = this.state;

		trading_data = (trading_data || []);

		trading_data = _.takeRight(trading_data, resultLength - (20 - trading_data[trading_data.length - 1]?.result_position || 0));

		let { tab_selected } = this.state;

		if(trading_data.length < resultLength){
			trading_data = [...trading_data, ...Array(resultLength-trading_data.length).fill({})];
		}

		let upCount = trading_data.filter(({ closePrice, openPrice }) => closePrice >= openPrice).length;
		let downCount = trading_data.filter(({ closePrice, openPrice }) => closePrice < openPrice).length;

		let trading_data_chunk = _.chunk(trading_data, 20);
		return <div data-v-5b2de64c="" id="analysis-wrapper" className="analysis-wrapper">
			<ul data-v-5b2de64c="" className="nav nav-pills tab-last-result">
				<li data-v-5b2de64c="" id="indicator" onClick={this.onChangeTab(TABS.indicator)} className="nav-item">
					<a data-v-5b2de64c="" className={setClassNameCondition(tab_selected === TABS.indicator, 'active', '', 'nav-link text-capitalize')}>
						Indicators
					</a>
				</li>
				<li data-v-5b2de64c="" id="last_result" onClick={this.onChangeTab(TABS.last_result)} className="nav-item">
					<a data-v-5b2de64c="" className={setClassNameCondition(tab_selected === TABS.last_result, 'active', '', 'nav-link text-capitalize')}>
						Last results
					</a>
				</li>
			</ul>
			<div data-v-320f0600="" data-v-5b2de64c="" className={setClassNameCondition(tab_selected === TABS.indicator, 'active', '', 'wrap-gauge-meter')}>
				<div data-v-320f0600="" className="wrap-gauge-meter-inner">
					<div data-v-320f0600="" className="gauge-meter">
						<div data-v-320f0600="" className="gauge-meter-sub gauge-meter--os">
							<div data-v-320f0600="" className="v-popover gauge-meter-popover">
								<div aria-describedby="popover_srcysvd6nd" className="trigger" style={{ display: 'inline-block' }}>
									<h3 data-v-320f0600="" className="gauge-meter-title">
										Oscillators
										<span data-v-320f0600="" className="gauge-meter-title-icon">
														<svg
															data-v-320f0600=""
															xmlns="http://www.w3.org/2000/svg"
															width="22" height="22"
															viewBox="0 0 22 22"><g
															data-v-320f0600=""
															id="c-question"
															transform="translate(3.068 -8.774)"><g
															data-v-320f0600=""
															id="Ellipse_1825"
															data-name="Ellipse 1825"
															transform="translate(-2.068 9.774)"
															fill="none"
															stroke="#fefefe"
															strokeLinecap="square"
															strokeMiterlimit="10"
															strokeWidth="1"><circle
															data-v-320f0600=""
															cx="10" cy="10" r="10"
															stroke="none" /> <circle
															data-v-320f0600=""
															cx="10" cy="10" r="10.5"
															fill="none" /></g> <g
															data-v-320f0600=""
															id="question"
															transform="translate(3.497 13.43)"><path
															data-v-320f0600=""
															id="Path_30768"
															data-name="Path 30768"
															d="M8.359,10.774a4.349,4.349,0,0,1,.4-2.323,5.026,5.026,0,0,1,1.4-1.421c1.317-.981,1.876-1.491,1.876-2.54,0-1.166-.924-1.637-2.166-1.637a7.306,7.306,0,0,0-3.334.866L5.694,2.063A9.2,9.2,0,0,1,10.035,1a4.89,4.89,0,0,1,3.1.9A3,3,0,0,1,14.287,4.4,3.24,3.24,0,0,1,13.4,6.7,10.58,10.58,0,0,1,11.872,8,5.71,5.71,0,0,0,10.63,9.137a2.68,2.68,0,0,0-.315,1.637H8.359Z"
															transform="translate(-5.694 -1)"
															fill="#fefefe" /> <ellipse
															data-v-320f0600=""
															id="Ellipse_1827"
															data-name="Ellipse 1827"
															cx="1.363" cy="1.284"
															rx="1.363" ry="1.284"
															transform="translate(2.252 11.56)"
															fill="#fefefe" /></g></g></svg>
													</span>
									</h3>
								</div>
							</div>
							<h4 data-v-320f0600="" className="gauge-meter-sub-title"> Neutral </h4>
							<div data-v-320f0600="" className="gauge-meter-border">
								<div data-v-320f0600="" className="gauge-meter-background rank-3" />
								<ul data-v-320f0600="" className="gauge-meter-label-list">
									<li data-v-320f0600="" className="gauge-meter-label-item gauge-meter-label-item--strong-sell"> strong<br data-v-320f0600="" />sell
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--sell">
										sell
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--neutral active">
										neutral
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--buy">
										buy
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--strong-buy">
										strong<br data-v-320f0600=""/>buy
									</li>
								</ul>
								<ul data-v-320f0600=""
									className="gauge-meter-status-list">
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--sell">4</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Sell</span>
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--neutral">2</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Neutral</span>
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--buy">3</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Buy</span>
									</li>
								</ul>
								<div data-v-320f0600="" className="gauge-meter-pane" data-highcharts-chart="0" style={{ overflow: 'hidden' }}>
									<HighchartsReact
										ref={ref => this.indicatorCharts['meter_os'] = ref}
										className="highcharts-container"
										highcharts={Highcharts}
										options={this.meta_os}
									/>
								</div>
							</div>
						</div>
						<div data-v-320f0600="" className="gauge-meter-sub gauge-meter--su">
							<div data-v-320f0600=""
								 className="v-popover gauge-meter-popover">
								<div
									aria-describedby="popover_cbw2leo8ih"
									className="trigger"
									style={{ display: 'inline-block' }}>
									<h3 data-v-320f0600=""
										className="gauge-meter-title">
										Summary
										<span data-v-320f0600=""
											  className="gauge-meter-title-icon"><svg
											data-v-320f0600=""
											xmlns="http://www.w3.org/2000/svg"
											width="22" height="22"
											viewBox="0 0 22 22"><g
											data-v-320f0600=""
											id="c-question"
											transform="translate(3.068 -8.774)"><g
											data-v-320f0600=""
											id="Ellipse_1825"
											data-name="Ellipse 1825"
											transform="translate(-2.068 9.774)"
											fill="none"
											stroke="#fefefe"
											strokeLinecap="square"
											strokeMiterlimit="10"
											strokeWidth="1"><circle
											data-v-320f0600=""
											cx="10" cy="10" r="10"
											stroke="none"/> <circle
											data-v-320f0600=""
											cx="10" cy="10" r="10.5"
											fill="none"/></g> <g
											data-v-320f0600=""
											id="question"
											transform="translate(3.497 13.43)"><path
											data-v-320f0600=""
											id="Path_30768"
											data-name="Path 30768"
											d="M8.359,10.774a4.349,4.349,0,0,1,.4-2.323,5.026,5.026,0,0,1,1.4-1.421c1.317-.981,1.876-1.491,1.876-2.54,0-1.166-.924-1.637-2.166-1.637a7.306,7.306,0,0,0-3.334.866L5.694,2.063A9.2,9.2,0,0,1,10.035,1a4.89,4.89,0,0,1,3.1.9A3,3,0,0,1,14.287,4.4,3.24,3.24,0,0,1,13.4,6.7,10.58,10.58,0,0,1,11.872,8,5.71,5.71,0,0,0,10.63,9.137a2.68,2.68,0,0,0-.315,1.637H8.359Z"
											transform="translate(-5.694 -1)"
											fill="#fefefe"/> <ellipse
											data-v-320f0600=""
											id="Ellipse_1827"
											data-name="Ellipse 1827"
											cx="1.363" cy="1.284"
											rx="1.363" ry="1.284"
											transform="translate(2.252 11.56)"
											fill="#fefefe"/></g></g></svg></span>
									</h3>
								</div>
							</div>
							<h4 data-v-320f0600=""
								className="gauge-meter-sub-title">Buy</h4>
							<div data-v-320f0600=""
								 className="gauge-meter-border">
								<div data-v-320f0600=""
									 className="gauge-meter-background rank-4"/>
								<ul data-v-320f0600=""
									className="gauge-meter-label-list">
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--strong-sell">
										strong<br data-v-320f0600="" />sell
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--sell">
										sell
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--neutral">
										neutral
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--buy active">
										buy
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--strong-buy">
										strong<br data-v-320f0600=""/>buy
									</li>
								</ul>
								<ul data-v-320f0600=""
									className="gauge-meter-status-list">
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--sell">4</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Sell</span>
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--neutral">2</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Neutral</span>
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--buy">15</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Buy</span>
									</li>
								</ul>
								<div data-v-320f0600=""
									 className="gauge-meter-pane"
									 data-highcharts-chart="1"
									 style={{ overflow: 'hidden' }}>
									<HighchartsReact
										ref={ref => this.indicatorCharts['meter_su'] = ref}
										className="highcharts-container"
										highcharts={Highcharts}
										options={this.meta_su}
									/>
								</div>
							</div>
						</div>
						<div data-v-320f0600="" className="gauge-meter-sub gauge-meter--ma">
							<div data-v-320f0600=""
								 className="v-popover gauge-meter-popover">
								<div
									aria-describedby="popover_wjx5xe9leq"
									className="trigger"
									style={{ display: 'inline-block' }}>
									<h3 data-v-320f0600=""
										className="gauge-meter-title">
										Moving Averages
										<span data-v-320f0600=""
											  className="gauge-meter-title-icon"><svg
											data-v-320f0600=""
											xmlns="http://www.w3.org/2000/svg"
											width="22" height="22"
											viewBox="0 0 22 22"><g
											data-v-320f0600=""
											id="c-question"
											transform="translate(3.068 -8.774)"><g
											data-v-320f0600=""
											id="Ellipse_1825"
											data-name="Ellipse 1825"
											transform="translate(-2.068 9.774)"
											fill="none"
											stroke="#fefefe"
											strokeLinecap="square"
											strokeMiterlimit="10"
											strokeWidth="1"><circle
											data-v-320f0600=""
											cx="10" cy="10" r="10"
											stroke="none"/> <circle
											data-v-320f0600=""
											cx="10" cy="10" r="10.5"
											fill="none"/></g> <g
											data-v-320f0600=""
											id="question"
											transform="translate(3.497 13.43)"><path
											data-v-320f0600=""
											id="Path_30768"
											data-name="Path 30768"
											d="M8.359,10.774a4.349,4.349,0,0,1,.4-2.323,5.026,5.026,0,0,1,1.4-1.421c1.317-.981,1.876-1.491,1.876-2.54,0-1.166-.924-1.637-2.166-1.637a7.306,7.306,0,0,0-3.334.866L5.694,2.063A9.2,9.2,0,0,1,10.035,1a4.89,4.89,0,0,1,3.1.9A3,3,0,0,1,14.287,4.4,3.24,3.24,0,0,1,13.4,6.7,10.58,10.58,0,0,1,11.872,8,5.71,5.71,0,0,0,10.63,9.137a2.68,2.68,0,0,0-.315,1.637H8.359Z"
											transform="translate(-5.694 -1)"
											fill="#fefefe"/> <ellipse
											data-v-320f0600=""
											id="Ellipse_1827"
											data-name="Ellipse 1827"
											cx="1.363" cy="1.284"
											rx="1.363" ry="1.284"
											transform="translate(2.252 11.56)"
											fill="#fefefe"/></g></g></svg></span>
									</h3>
								</div>
							</div>
							<h4 data-v-320f0600=""
								className="gauge-meter-sub-title">
								Strong Buy
							</h4>
							<div data-v-320f0600=""
								 className="gauge-meter-border">
								<div data-v-320f0600="" className="gauge-meter-background rank-5" />
								<ul data-v-320f0600=""
									className="gauge-meter-label-list">
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--strong-sell">
										strong<br data-v-320f0600=""/>sell
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--sell">
										sell
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--neutral">
										neutral
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--buy">
										buy
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-label-item gauge-meter-label-item--strong-buy active">
										strong<br data-v-320f0600="" />buy
									</li>
								</ul>
								<ul data-v-320f0600=""
									className="gauge-meter-status-list">
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--sell">0</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Sell</span>
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--neutral">0</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Neutral</span>
									</li>
									<li data-v-320f0600=""
										className="gauge-meter-status-item">
																							<span data-v-320f0600=""
																								  className="gauge-meter-status-value gauge-meter-status-value--buy">12</span>
										<span data-v-320f0600=""
											  className="gauge-meter-status-text">Buy</span>
									</li>
								</ul>
								<div data-v-320f0600=""
									 className="gauge-meter-pane"
									 data-highcharts-chart="2"
									 style={{ overflow: 'hidden' }}>
									<HighchartsReact
										ref={ref => this.indicatorCharts['meter_ma'] = ref}
										className="highcharts-container"
										highcharts={Highcharts}
										options={this.meta_ma}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div data-v-5b2de64c="" id="historyBox" className={setClassNameCondition(tab_selected === TABS.last_result, 'active', '', 'pt-3 historyBox')}>
				<div data-v-cbecf09c="" data-v-5b2de64c="" className="overviewInfo d-flex align-items-center">
								<span data-v-cbecf09c="" className="badgeItem">
									<span data-v-cbecf09c="" className="color-green text-uppercase font-weight-700">Tăng </span>
									<span data-v-cbecf09c="">{upCount}</span>
								</span>
					<span data-v-cbecf09c="" className="badgeItem ml-2">
									<span data-v-cbecf09c="" className="color-red  text-uppercase font-weight-700">Giảm </span>
									<span data-v-cbecf09c="">{downCount}</span>
								</span>
				</div>
				<div data-v-ff3ba252="" data-v-5b2de64c="" className="row justify-content-between blockResult">
					{trading_data_chunk.map((chunk, index0) => {
						let chunk_rows = _.chunk(chunk, 4);
						return (
							<div data-v-ff3ba252=""  key={'block' +index0} className="col">
								{chunk_rows.map((chunk_row, index1) => {
									return (
										<div  key={'col' + index1} className='row-rs'>
											{chunk_row.map(({ closePrice, openPrice }, index2) => (<div key={'row'+index0+index1+index2} className={closePrice > 0 ? setClassNameCondition(closePrice > openPrice, 'up', 'down', 'col-rs') : 'col-rs'} />))}
										</div>
									)
								})}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(AnalysisWrapper));

