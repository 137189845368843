import  { Component, createRef } from 'react';
import {connect} from "react-redux";
import {
	formatNumber,
	isCurrentRoute,
	navigateTo,
	showNotification,
	handleClickOutside,
	isDesktop,
	isMobile,
	setClassNameCondition
} from '../lib/helper';
import {withRouter} from 'react-router-dom';
import { fetchingUser, fetchingUserSuccess, updateAppConfigAction, fetchingTransactionAction } from "../redux/actions";
import api from "../lib/api";
import constants from "../lib/constants";
import Deposit from "./trading/partial/Deposit";
import TransactionBox from "../lib/custom/TransactionBox";

class Header extends Component{
	constructor(props) {
		super(props);
		this.state = {
			is_show_dropdown: false,
			is_show_helper: false,
			is_show_setting: false,

			enable_sound: 1,
			is_show_balance: 1
		};
		this.refList = {
			is_show_dropdown: createRef(),
			is_show_helper: createRef(),
			is_show_setting: createRef(),
		};
	}

	componentDidMount() {
		let {enable_sound, is_show_balance} = this.props.memberReducer || {};
		this.setState({ enable_sound, is_show_balance });
		document.addEventListener('click', handleClickOutside.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener('click', handleClickOutside.bind(this))
	}

	onChangePointType = (point_type) => () => {
		api.putChangeProfile({ current_point_type: point_type })
			.then(({ data, message, success }) => {
				if(success){
					this.props.fetchingUserSuccess(data);
				} else {
					showNotification({ message, type: 'danger' });
				}
			});
		this.onChangeState('is_show_dropdown')();
	}

	onResetDemoBalance = (e) => {
		e.preventDefault();
		api.resetDemoBalance().then(({data, success, message}) => {
			if(success){
				this.props.fetchingUserSuccess(data);
			}
			showNotification({ message, type: success ? 'success' : 'danger' });
			this.onChangeState('is_show_dropdown')();
		});
	}

	onClickChangeSetting = (type) => () => {
		let value = this.state[type] === 1 ? 0 : 1;
		this.setState({ [type]: value }, () => {
			api.putChangeProfile({ [type]: value })
				.then(({data, success, message}) => {
					if(success){
						return this.props.fetchingUserSuccess(data);
					}
					showNotification({ message, type: success ? 'success' : 'danger' });
				});
		});
	}

	onToggle = (type) => (e) => {
		e.preventDefault();
		this.props.updateAppConfigAction({ [type]: !this.props.appReducer[type] });
		if(type === 'is_show_transaction'){
			this.props.fetchingTransactionAction();
		}
	}

	onChangeState = (type) => (e) => {
		e && e.preventDefault();
		this.setState({ [type]: !this.state[type] });
	}

	render() {
		let { login_token, demo_balance, real_balance, current_point_type } = this.props.memberReducer || {};
		let balance = this.props.memberReducer[`${current_point_type}_balance`];
		let { is_show_dropdown, is_show_helper, is_show_setting, enable_sound, is_show_balance } = this.state;
		let { phone, is_show_transaction, is_show_menu, asset_logo } = this.props.appReducer;
		let { total_bet_open } = this.props.transactionReducer;

		return <div data-v-bd82969a="" data-v-b0e45e8c="" className="headerMaster">
			<div data-v-bd82969a="" className="boxHeader navbar fixed-top">
				<div data-v-bd82969a="" className={ 'headerWapper ' + (isCurrentRoute(constants.ROUTE.HOME) ? 'notAuthentication' : '') }>
					<header data-v-bd82969a="" className="d-flex justify-content-between align-items-center w-100">
						<div data-v-bd82969a="" id="leftNav">
							{isMobile() && !isCurrentRoute(constants.ROUTE.HOME) && <div data-v-bd82969a="" className="mobile menuMobileIcon">
								<span data-v-bd82969a=""
									  onClick={this.onToggle('is_show_menu')}
									  className={'mobileIcon ' + (is_show_menu ? 'show' : 'hide')}><svg
								data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="23.944" height="19.953"
								viewBox="0 0 23.944 19.953"><g data-v-bd82969a="" id="menu-4"
															   transform="translate(0 -1)"><path data-v-bd82969a=""
																								 id="Path_29261"
																								 data-name="Path 29261"
																								 d="M21.949,5H2A2,2,0,0,0,2,8.991H21.949a2,2,0,1,0,0-3.991Z"
																								 transform="translate(0 3.981)"
																								 fill="#fff"/> <path
								data-v-bd82969a="" id="Path_29262" data-name="Path 29262"
								d="M21.949,1H2A2,2,0,0,0,2,4.991H21.949a2,2,0,1,0,0-3.991Z" fill="#fff" /> <path
								data-v-bd82969a="" id="Path_29263" data-name="Path 29263"
								d="M9.977,9H2a2,2,0,0,0,0,3.991H9.977A2,2,0,1,0,9.977,9Z" transform="translate(0 7.963)"
								fill="#fff" /></g></svg></span>
							</div>}
							<a data-v-bd82969a="" href="#" onClick={navigateTo().bind(this)} className="logoMobile router-link-exact-active router-link-active">
								{(isCurrentRoute(constants.ROUTE.HOME) || isDesktop()) && <img data-v-bd82969a="" width={isDesktop() ? 208 : 151} height="35"
								src={asset_logo}
								alt="" />}
							</a>
							{isMobile() && !isCurrentRoute(constants.ROUTE.HOME) && <Deposit />}
						</div>
						<div data-v-bd82969a="" id="rightNav">
							{login_token ?
								(
									isCurrentRoute(constants.ROUTE.HOME) ?
										<div data-v-bd82969a="" className={'d-flex align-items-center pr-2 ' + (isDesktop() ? '' : 'groupAuthentication')}>
											<a data-v-bd82969a="" href="#" onClick={navigateTo('trading').bind(this)} className="button wbtn btn-large btn-radius started">
												<span data-v-bd82969a="" className={'font-18 text-capitalize ' + (isDesktop() ? '' : 'font-14m')}>Giao dịch</span>
											</a>
										</div>
									:
									<ul data-v-bd82969a="" className="nav infoMem align-items-center">
										<li data-v-bd82969a="" className="balance" ref={this.refList.is_show_dropdown}>
											<div data-v-bd82969a="" className={'dropdown pointer ' + (is_show_dropdown ? 'show' : '')}>
												<a data-v-bd82969a="" role="button" data-toggle="dropdown"
												   aria-haspopup="true" aria-expanded={is_show_dropdown}
												   onClick={this.onChangeState('is_show_dropdown')}
												   className="buttonBalance d-flex align-items-center">
													<div data-v-bd82969a="" className="d-flex flex-column mr-lg-2 mr-2"><span
														data-v-bd82969a=""
														className="font-12 font-8m text-left color-white mb-1">Tài khoản {current_point_type === 'demo' ? 'Demo' : 'Thực'}</span>
														<div data-v-bd82969a=""
															 className="d-flex align-items-center"><span
															data-v-bd82969a="" className="colorWhite font-18"><b
															data-v-bd82969a="">${formatNumber(balance)}</b></span></div>
													</div>
													<span data-v-bd82969a="" className="arrow">
													<svg
														data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg"
														width="10" viewBox="0 0 32 32"><g data-v-bd82969a=""
																						  strokeLinecap="square"
																						  strokeLinejoin="miter"
																						  strokeWidth="2"
																						  fill="#111111"
																						  stroke="#111111"
																						  className="nc-icon-wrapper"><g
														data-v-bd82969a="" className="nc-interact_sorting-o-32"><path
														data-v-bd82969a="" data-cap="none" fill="none"
														stroke="#ffffff" strokeLinecap="round"
														strokeLinejoin="round" d="M4 10l12 12"
														strokeDasharray="16.97 16.97"
														strokeDashoffset="0"/> <path data-v-bd82969a=""
																					 data-cap="none"
																					 fill="none"
																					 stroke="#ffffff"
																					 strokeLinecap="round"
																					 strokeLinejoin="round"
																					 d="M28 10L16 22"
																					 strokeDasharray="16.97 16.97"
																					 strokeDashoffset="0"/></g></g></svg>
												</span>
												</a>
												<div data-v-bd82969a="" className={'dropdown-menu sub-menu ' + (is_show_dropdown ? 'show' : '')}>
													<div data-v-bd82969a="" className="boxItemRadio pointer">
														<div data-v-bd82969a="" className="d-flex align-items-center">
															<div data-v-bd82969a="" className="flexLeft">
																<div data-v-bd82969a="" onClick={this.onChangePointType('real')}  className="boxSelectAccount d-flex">
																	<span data-v-bd82969a="" className={'radioButton mr-2 ' + (current_point_type === 'real' ? 'active' : '')} />
																	<div data-v-bd82969a="">
																		<div data-v-bd82969a="" className="d-flex flex-column">
																			<span data-v-bd82969a="" className="color-white-50 font-12 font-10m mb-1">Tài khoản Thực</span>
																			<div data-v-bd82969a="" className="d-flex align-items-center">
																			<span data-v-bd82969a="" className="font-18 font-12m font-weight-700 price color-white d-flex">
																				<span data-v-bd82969a="">${formatNumber(real_balance)}</span>
																			</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div data-v-bd82969a="" className="flexRight d-flex justify-content-end align-items-center">
																<a data-v-bd82969a="" href="#" className="buttonDeposit font-12 font-10m">
																	<svg data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="46" height="29"/>
																</a>
															</div>
														</div>
													</div>
													<div data-v-bd82969a="" className="boxItemRadio pointer">
														<div data-v-bd82969a="" className="d-flex align-items-center">
															<div data-v-bd82969a="" className="flexLeft">
																<div data-v-bd82969a="" onClick={this.onChangePointType('demo')} className="boxSelectAccount d-flex">
																	<span data-v-bd82969a="" className={'radioButton mr-2 ' + (current_point_type === 'demo' ? 'active' : '')}/>
																	<div data-v-bd82969a="">
																		<div data-v-bd82969a=""
																			 className="d-flex flex-column">
																						<span data-v-bd82969a=""
																							  className="color-white-50 font-12 font-10m mb-1">Tài khoản Demo</span>
																			<div data-v-bd82969a=""
																				 className="d-flex align-items-center">
																							<span data-v-bd82969a=""
																								  className="font-18 font-12m font-weight-700 price color-white d-flex"><span
																								data-v-bd82969a="">${formatNumber(demo_balance)}</span></span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div data-v-bd82969a="" className="flexRight d-flex justify-content-end align-items-center">
																<a data-v-bd82969a=""
																   onClick={this.onResetDemoBalance}
																   href="#"><img
																	data-v-bd82969a="" width="19"
																	height="19"
																	src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy43OTUiIGhlaWdodD0iMTguNDg4IiB2aWV3Qm94PSIwIDAgMTcuNzk1IDE4LjQ4OCI+CiAgPGcgaWQ9InJlZnJlc2gtMDEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIDAuMDgxKSI+CiAgICA8cGF0aCBpZD0iUGF0aF8yNjI1OSIgZGF0YS1uYW1lPSJQYXRoIDI2MjU5IiBkPSJNMTguOCw3LjM2NiwxNy41NTUtLjA4MSwxNC45OTMsMi40ODFhOC44LDguOCwwLDEsMCwyLjksMTAuNjQxLjguOCwwLDAsMC0xLjQ2OC0uNjQyLDcuMjE1LDcuMjE1LDAsMSwxLTIuNTczLTguODU0bC0yLjUsMi41WiIgZmlsbD0iI2ZmZiIvPgogIDwvZz4KPC9zdmc+Cg=="
																	alt=""/></a></div>
														</div>
													</div>
												</div>
											</div>
										</li>
										{isDesktop() && <Deposit />}
										<li data-v-bd82969a="" ref={this.refList.is_show_setting} className="settings">
											<div data-v-bd82969a=""
												 onClick={this.onChangeState('is_show_setting')}
												 className="buttonSetting d-block align-items-center pointer pc">
															<span data-v-bd82969a="" className="icon"><svg
																data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg"
																width="17.281" height="17.283"
																viewBox="0 0 17.281 17.283"><g data-v-bd82969a=""
																							   id="gear"
																							   transform="translate(-2.004 -1.995)"><path
																data-v-bd82969a="" id="Path_30727"
																data-name="Path 30727"
																d="M18.111,9.362,16.772,9.25a6.245,6.245,0,0,0-.814-1.96l.871-1.03a1.28,1.28,0,0,0-1.8-1.8L14,5.326a6.234,6.234,0,0,0-1.963-.816L11.92,3.17a1.28,1.28,0,0,0-2.552,0L9.256,4.51a6.245,6.245,0,0,0-1.96.814l-1.03-.868a1.28,1.28,0,0,0-1.8,1.8l.871,1.029a6.239,6.239,0,0,0-.816,1.961l-1.341.112a1.281,1.281,0,0,0,0,2.552l1.341.112a6.238,6.238,0,0,0,.814,1.96l-.871,1.03a1.28,1.28,0,0,0,1.8,1.8L7.3,15.95a6.239,6.239,0,0,0,1.961.816l.112,1.341a1.281,1.281,0,0,0,2.552,0l.112-1.341a6.238,6.238,0,0,0,1.96-.814l1.03.871a1.28,1.28,0,0,0,1.8-1.8l-.871-1.029a6.233,6.233,0,0,0,.816-1.961l1.341-.112a1.281,1.281,0,0,0,0-2.552Zm-7.467,4.445a3.168,3.168,0,1,1,3.168-3.168A3.168,3.168,0,0,1,10.644,13.806Z"
																fill="#89809f"/></g></svg></span> <span
												data-v-bd82969a="">Cài đặt</span>
											</div>
											{is_show_setting && <div data-v-c80df956="" data-v-bd82969a="">
												<div data-v-c80df956="" className="settings">
													<div data-v-c80df956=""
														 className="dropdown-menu dropdownSetting d-flex flex-column show">
														<div data-v-c80df956="" className="wrap">
															<div data-v-c80df956="" className="line mb-3">
																<div data-v-c80df956="" className="type">
																	<svg data-v-c80df956="" version="1.1" width="19" height="19" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19 19" xmlSpace="preserve"><g data-v-c80df956="" id="sound" transform="translate(0.5 -0.5)"><g data-v-c80df956="" id="Path_30736" fill="#887F9E"><path data-v-c80df956="" d="M13.7,19.3c-0.2,0-0.4-0.1-0.6-0.2l-6.9-4.6H0.5c-0.6,0-1-0.4-1-1V6.3c0-0.6,0.4-1,1-1h5.7l6.9-4.6
                                        c0.3-0.2,0.7-0.2,1,0c0.3,0.2,0.5,0.5,0.5,0.9v16.8c0,0.4-0.2,0.7-0.5,0.9C14,19.3,13.9,19.3,13.7,19.3z M1.5,12.5h5
                                        c0.2,0,0.4,0.1,0.6,0.2l5.6,3.8V3.4L7.1,7.1C6.9,7.2,6.7,7.3,6.5,7.3h-5V12.5z" className="st0"/></g> <g
																		data-v-c80df956="" id="Path_30737" fill="#887F9E"><path data-v-c80df956="" d="M16.8,15.1c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c1.9-2,1.9-5.1-0.1-7
                                        c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0c2.8,2.7,2.8,7.1,0.1,9.9c0,0-0.1,0.1-0.1,0.1C17.2,15,17,15.1,16.8,15.1z" className="st0"/></g></g></svg>
																	<span data-v-c80df956=""
																		  className="colorWhite">Âm thanh</span></div>
																<div data-v-c80df956="" className="control">
																	<div data-v-272fdeda="" data-v-c80df956=""
																		 className="toggleButtonWrapper"><span
																		data-v-272fdeda="" className="colorWhite">Off</span>
																		<div data-v-272fdeda="" onClick={this.onClickChangeSetting('enable_sound')} className={'toggleButton pointer isButtonOnOff ' + (enable_sound ? 'hasBg' : '') }>
																			<div data-v-272fdeda="" className={'status ' + (enable_sound ? 'yes' : '')}/>
																		</div>
																		<span data-v-272fdeda="" className="colorWhite">On</span></div>
																</div>
															</div>
															<div data-v-c80df956="" className="line">
																<div data-v-c80df956="" className="type">
																	<svg data-v-c80df956=""
																		 xmlns="http://www.w3.org/2000/svg" width="18"
																		 height="13.543" viewBox="0 0 18 13.543">
																		<g data-v-c80df956="" id="preview"
																		   transform="translate(-734.025 -2)" opacity="0.5">
																			<path data-v-c80df956="" id="Path_30402"
																				  data-name="Path 30402"
																				  d="M9.025,15.543c4.063,0,7.223-3.5,8.577-5.53a2.2,2.2,0,0,0,0-2.6C16.248,5.5,13.088,2,9.025,2S1.8,5.5.448,7.53a2.031,2.031,0,0,0,0,2.483C1.8,12.044,4.962,15.543,9.025,15.543Zm0-10.157a3.325,3.325,0,0,1,3.386,3.386,3.325,3.325,0,0,1-3.386,3.386A3.325,3.325,0,0,1,5.64,8.771,3.325,3.325,0,0,1,9.025,5.386Z"
																				  transform="translate(734)"
																				  fill="#fff"/>
																		</g>
																	</svg>
																	<span data-v-c80df956="" className="colorWhite">Hiện số dư
                            </span></div>
																<div data-v-c80df956="" className="control">
																	<div data-v-272fdeda="" data-v-c80df956=""
																		 className="toggleButtonWrapper"><span
																		data-v-272fdeda="" className="colorWhite">Off</span>
																		<div data-v-272fdeda=""
																			 onClick={this.onClickChangeSetting('is_show_balance')}
																			 className={'toggleButton pointer isButtonOnOff ' + (is_show_balance ? 'hasBg' : '') }>
																			<div data-v-272fdeda=""
																				 className={'status ' + (is_show_balance ? 'yes' : '')}/>
																		</div>
																		<span data-v-272fdeda=""
																			  className="colorWhite">On</span></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>}
										</li>
										<li data-v-bd82969a="" className="settings"><a data-v-bd82969a=""
																					   href="#"
																					   onClick={navigateTo('profile').bind(this)}
																					   className="nav-link p-0"
																					   id="profileLink"
																					   title="Administrators">
											<div data-v-bd82969a=""
												 className="buttonSetting d-block align-items-center pointer pc">
															<span data-v-bd82969a="" className="icon"><svg
																data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg"
																width="17.281" height="17.283" viewBox="0 0 24 24"><g
																data-v-bd82969a="" id="circle-10"
																transform="translate(-1.164 -1.164)"><path
																data-v-bd82969a="" id="Path_29003"
																data-name="Path 29003"
																d="M33.642,36.066V35.3a3.093,3.093,0,0,0-1.558-2.685l-3.078-1.765"
																transform="translate(-13.086 -13.978)" fill="none"
																stroke="#89809f" strokeMiterlimit="10"
																strokeWidth="2"/> <path data-v-bd82969a=""
																						id="Path_29004"
																						data-name="Path 29004"
																						d="M14.636,30.847l-3.078,1.765A3.093,3.093,0,0,0,10,35.3v.768"
																						transform="translate(-3.876 -13.978)"
																						fill="none"
																						stroke="#89809f"
																						strokeMiterlimit="10"
																						strokeWidth="2"/> <path
																data-v-bd82969a="" id="Path_29005"
																data-name="Path 29005"
																d="M20.124,22.824h0c-2.277,0-4.124-2.362-4.124-4.639V16.124A4.124,4.124,0,0,1,20.124,12h0a4.124,4.124,0,0,1,4.124,4.124v2.062C24.247,20.463,22.4,22.824,20.124,22.824Z"
																transform="translate(-6.784 -4.846)" fill="none"
																stroke="#89809f" strokeLinecap="square"
																strokeMiterlimit="10" strokeWidth="2"/> <circle
																data-v-bd82969a="" id="Ellipse_1365"
																data-name="Ellipse 1365" cx="11" cy="11" r="11"
																transform="translate(2.164 2.164)" fill="none"
																stroke="#89809f" strokeLinecap="square"
																strokeMiterlimit="10"
																strokeWidth="2"/></g></svg></span> <span
												data-v-bd82969a=""
												className="font-14 txtProfile">Hồ sơ</span></div>
										</a>
										</li>

										{isDesktop() && <li data-v-bd82969a=""><span data-v-bd82969a="" className="line" /></li>}

										<li data-v-bd82969a="" className="settings"  ref={this.refList.is_show_helper}>
											<a data-v-bd82969a="" href="#" onClick={this.onChangeState('is_show_helper')} className="buttonSetting d-block align-items-center pointer text-capitalize pc">
											<span data-v-bd82969a="" className="icon">
												<svg data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="17.844" height="19.428" viewBox="0 0 17.844 19.428">
													<g data-v-bd82969a="" id="headset" transform="translate(-3 -1)">
														<path data-v-bd82969a="" id="Path_29575" data-name="Path 29575" d="M28,38.753h3.961a2.376,2.376,0,0,0,2.377-2.377V34" transform="translate(-14.494 -19.325)" fill="none" stroke="#89809f" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
														<path data-v-bd82969a="" id="Path_29576" data-name="Path 29576" d="M7.961,24H4v3.961a1.584,1.584,0,0,0,1.584,1.584H7.961Z" transform="translate(0 -13.286)" fill="none" stroke="#89809f" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
														<path data-v-bd82969a="" id="Path_29577" data-name="Path 29577" d="M37.961,24H34v5.545h2.377a1.584,1.584,0,0,0,1.584-1.584Z" transform="translate(-18.117 -13.286)" fill="none" stroke="#89809f" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
														<path data-v-bd82969a="" id="Path_29578" data-name="Path 29578" d="M19.844,10.714V9.922A7.922,7.922,0,0,0,11.922,2h0A7.922,7.922,0,0,0,4,9.922v.792" fill="none" stroke="#89809f" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2"/>
													</g>
												</svg>
											</span>
												<span data-v-bd82969a="">Trợ giúp</span>
											</a>
											{is_show_helper && <div className="notification-dropdown-body custom max-content">
												<div className="notification-dropdown-header custom">
													<h3 className="notification-dropdown-title">
														<small>Liên hệ:</small>
														<a className='notification-dropdown-phone' target='_blank' href={phone}>{phone}</a>
													</h3>
												</div>
											</div>}
										</li>
										{isCurrentRoute(constants.ROUTE.TRADING) && isDesktop() && <li data-v-bd82969a="">
											<div data-v-bd82969a="" onClick={this.onToggle('is_show_transaction')} className="buttonToggle pointer">
												<svg data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="3.331"
													 height="14.434" viewBox="0 0 3.331 14.434" className="iconClose">
													<g data-v-bd82969a="" id="menu" transform="translate(0 -61)">
														<g data-v-bd82969a="" id="Group_13136" data-name="Group 13136"
														   transform="translate(0 61)">
															<g data-v-bd82969a="" id="Group_13135"
															   data-name="Group 13135">
																<path data-v-bd82969a="" id="Path_29631"
																	  data-name="Path 29631"
																	  d="M1.665,61a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,61Z"
																	  transform="translate(0 -61)" fill="#fff"/>
															</g>
														</g>
														<g data-v-bd82969a="" id="Group_13138" data-name="Group 13138"
														   transform="translate(0 66.551)">
															<g data-v-bd82969a="" id="Group_13137"
															   data-name="Group 13137">
																<path data-v-bd82969a="" id="Path_29632"
																	  data-name="Path 29632"
																	  d="M1.665,211a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,211Z"
																	  transform="translate(0 -211)" fill="#fff"/>
															</g>
														</g>
														<g data-v-bd82969a="" id="Group_13140" data-name="Group 13140"
														   transform="translate(0 72.103)">
															<g data-v-bd82969a="" id="Group_13139"
															   data-name="Group 13139">
																<path data-v-bd82969a="" id="Path_29633"
																	  data-name="Path 29633"
																	  d="M1.665,361a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,361Z"
																	  transform="translate(0 -361)" fill="#fff"/>
															</g>
														</g>
													</g>
												</svg>
												<span data-v-bd82969a="" className="totalCount text-uppercase" style={{ display: total_bet_open > 0 ? 'initial' : 'none' }}>{total_bet_open}</span>
											</div>
										</li>}
										{isCurrentRoute(constants.ROUTE.TRADING) && isMobile() && <li data-v-bd82969a="">
											<div data-v-bd82969a="" className={setClassNameCondition(is_show_transaction, 'notification-dropdown--active', '', 'notification-dropdown mobile notification-transaction')}>
												<div className="notification-dropdown-button mobile" onClick={this.onToggle('is_show_transaction')}>
													<span className="notification-dropdown-button-icon">
														<svg data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="3.331" height="14.434" viewBox="0 0 3.331 14.434"
															 className="iconClose">
															<g data-v-bd82969a="" id="menu" transform="translate(0 -61)"><g data-v-bd82969a=""
																																				   id="Group_13136"
																																				   data-name="Group 13136"
																																				   transform="translate(0 61)"><g
															data-v-bd82969a="" id="Group_13135" data-name="Group 13135"><path data-v-bd82969a="" id="Path_29631"
																															  data-name="Path 29631"
																															  d="M1.665,61a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,61Z"
																															  transform="translate(0 -61)"
																															  fill="#fff" /></g></g><g data-v-bd82969a=""
																																							id="Group_13138"
																																							data-name="Group 13138"
																																							transform="translate(0 66.551)"><g
															data-v-bd82969a="" id="Group_13137" data-name="Group 13137"><path data-v-bd82969a="" id="Path_29632"
																															  data-name="Path 29632"
																															  d="M1.665,211a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,211Z"
																															  transform="translate(0 -211)" fill="#fff" /></g></g><g
															data-v-bd82969a="" id="Group_13140" data-name="Group 13140" transform="translate(0 72.103)"><g data-v-bd82969a=""
																																						   id="Group_13139"
																																						   data-name="Group 13139"><path
															data-v-bd82969a="" id="Path_29633" data-name="Path 29633"
															d="M1.665,361a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,361Z" transform="translate(0 -361)"
															fill="#fff" /></g></g></g>
														</svg>
													</span>
													<span className="notification-dropdown-button-number" style={{ display: total_bet_open > 0 ? 'initial' : 'none' }}>{total_bet_open}</span>
												</div>
												{is_show_transaction && <>
													<div className="notification-dropdown-body">
														<div className="notification-dropdown-header">
															<h3 className="notification-dropdown-title">Giao dịch gần đây</h3>
															<span className="notification-dropdown-close pe pe-7s-close" onClick={this.onToggle('is_show_transaction')} />
														</div>
														<div className="notification-dropdown-list">
															<div data-v-5b2de64c="" className="flex-100 px-1">
																<TransactionBox />
															</div>
														</div>
													</div>
													<div className="notification-dropdown-mask"  onClick={this.onToggle('is_show_transaction')} />
												</>}
											</div>
										</li>}
									</ul>
								)
								:
								<div data-v-bd82969a="" className={'d-flex align-items-center pr-2 ' + (isDesktop() ? '' : 'groupAuthentication')}>
									<a data-v-bd82969a="" href="#" onClick={navigateTo('/login').bind(this)} className="button wbtn btn-large btn-radius btn-transparent mr-lg-2 color-white">
										<span data-v-bd82969a="" className={'font-18 text-capitalize ' + (isDesktop() ? '' : 'font-14m')}>Đăng nhập</span>
									</a>
									<a data-v-bd82969a="" href="#" onClick={navigateTo('/register').bind(this)} className="button wbtn btn-large btn-radius started">
										<span data-v-bd82969a="" className={'font-18 text-capitalize ' + (isDesktop() ? '' : 'font-14m')}>Đăng ký</span>
									</a>
								</div>
							}
						</div>
					</header>
				</div>
			</div>
		</div>
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer,
		transactionReducer: state.transactionReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess,
	updateAppConfigAction,
	fetchingTransactionAction
})(withRouter(Header));
