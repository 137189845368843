import { Component } from 'react'

class Countdown extends Component{
	constructor(props) {
		super(props);
		this.state = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		};
	}

	componentDidMount() {
		let { countdownEnd } = this.props;
		this.countdown = setInterval(() => {
			let now = new Date().getTime();
			let distance = countdownEnd - now;

			let days = Math.floor(distance / (1000 * 60 * 60 * 24));
			let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((distance % (1000 * 60)) / 1000);

			this.setState({ days, hours, minutes, seconds });

			if (distance < 0) {
				clearInterval(this.countdown);
			}
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.countdown);
	}

	render() {
		let { days, hours, minutes, seconds } = this.state;
		return (
				<ul data-v-5238713e="" data-v-af39567a="" className="vuejs-countdown">
					<li data-v-5238713e=""><p data-v-5238713e=""
											  className="digit">{days}</p><p
						data-v-5238713e="" className="text">{days > 0 ? 'Days' : 'Day'}</p></li>
					<li data-v-5238713e=""><p data-v-5238713e=""
											  className="digit">{hours}</p><p
						data-v-5238713e="" className="text">{hours > 0 ? 'Hours' : 'Hour'}</p>
					</li>
					<li data-v-5238713e=""><p data-v-5238713e=""
											  className="digit">{minutes}</p><p
						data-v-5238713e="" className="text">{minutes > 0 ? 'Mins' : 'Min'}</p>
					</li>
					<li data-v-5238713e=""><p data-v-5238713e=""
											  className="digit">{seconds}</p><p
						data-v-5238713e="" className="text">{seconds > 0 ? 'Secs' : 'Sec'}</p>
					</li>
				</ul>
		)
	}
}

export default Countdown;
